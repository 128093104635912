import { DesktopOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { AppRoutesName } from "../AppRoutesName";
import { SideBarItem } from "./sidebar.type";

export const AdminSidebarRoutes: SideBarItem[] = [
  {
    label: "Клиенты",
    key: "/",
    icon: <UsergroupAddOutlined />,
    link: "/",
  },
  {
    label: "База тренировок",
    key: AppRoutesName.trainingBase,
    icon: <DesktopOutlined />,
    link: AppRoutesName.trainingBase,
  },
  // {
  //   label: "Обращения",
  //   key: AppRoutesName.appeals,
  //   icon: <CommentOutlined />,
  //   link: AppRoutesName.appeals,
  // },
  // {
  //   label: "Модерация",
  //   key: AppRoutesName.moderation,
  //   icon: <FileSearchOutlined />,
  //   link: AppRoutesName.moderation,
  // },
  // {
  //   label: "Обратная связь",
  //   key: AppRoutesName.feedback,
  //   icon:<StarOutlined />,
  //   link: AppRoutesName.feedback,
  // },
];
