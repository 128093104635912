/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Select, Table, TableColumnsType } from "antd";
import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import s from "./ComplianceSales.module.scss";

interface DataType {
  key: React.Key;
  name: ReactNode;
  percent: string;
  action: ReactNode;
}

type EquipmentProps = {
  name: string;
  status: boolean;
};
const EquipmentName: FC<EquipmentProps> = ({ name, status }) => {
  return (
    <div className={s.equipment}>
      <span
        className={s.status}
        style={status ? { backgroundColor: "#88C68E" } : { backgroundColor: "#E07F7F" }}
      />
      <span className={s.name}>{name}</span>
    </div>
  );
};

const ComplianceSales = () => {
  const navigate = useNavigate();

  const handleSelect = (value: string) => {
    if (value === "script") {
      navigate("/reports/script/3434");
    }
    if (value === "postgres") {
      navigate("/reports/postgres/3434");
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Наименование техники",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "%",
      dataIndex: "percent",
      key: "percent",
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
    },
  ];

  const TableData: DataType[] = [
    {
      key: 1,
      name: (
        <EquipmentName
          name="ПОДТВЕРЖДЕНИЯ (паузы между порцией информации и реакция клиента)"
          status
        />
      ),
      percent: "45",
      action: "",
    },
  ];

  return (
    <div className={s.root}>
      <div className={s.root}>
        <div className={s.header}>
          <div className={s.filter}>
            <Select className={s.select} placeholder="Выберите период">
              <Select.Option value="monthly">Месячный</Select.Option>
            </Select>
            <Select
              className={s.select}
              placeholder="Выберите"
              defaultValue="sales"
              onSelect={handleSelect}
            >
              <Select.Option value="sales">Соблюдение техник продаж</Select.Option>
              <Select.Option value="script">Соблюдение скрипта</Select.Option>
              <Select.Option value="postgres">Прогресс менеджера</Select.Option>
            </Select>
          </div>
          <Button>Экспорт </Button>
        </div>
        <Breadcrumb
          className={s.breadcrumb}
          items={[
            {
              title: <a href="/reports">Общий</a>,
            },
            {
              title: <a href="#">Жакупова Айдана</a>,
            },
          ]}
        />
        <div className={s.table}>
          <Table columns={columns} bordered dataSource={TableData} />
        </div>
      </div>
    </div>
  );
};

export { ComplianceSales };
