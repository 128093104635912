import { ConfigProvider } from "antd";
import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./router/AppRoutes";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "Inter",
      },
    }}
  >
    <React.StrictMode>
      <AppRoutes />
    </React.StrictMode>
  </ConfigProvider>,
);

// Если хотите начать измерение производительности в вашем приложении, передайте функцию
// для логирования результатов (например: reportWebVitals(console.log))
// или отправьте их на аналитическую конечную точку. Узнайте больше: https://bit.ly/CRA-vitals
reportWebVitals();
