/* eslint-disable class-methods-use-this */
import axios from "axios";
import { DOMAIN } from "../../constants";

class AccountsApi {
  async login(email: string, password: string) {
    const { data } = await axios.post(`${DOMAIN}/api/accounts/login/`, {
      email,
      password,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
    console.log(data);
  }

  logout() {
    localStorage.removeItem("userInfo");
  }
}

const accountsApi = new AccountsApi();

export default accountsApi;
