import { Form, message } from "antd";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../api";
import Button from "../../components/ui/Button";
import { TextField } from "../../components/ui/TextField";
import s from "./Login.module.scss";

const ResetPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = useParams();

  const handleSubmit = async (values: any) => {
    try {
      await ApiService.apiPasswordResetConfirmCreate({
        password: values.password,
        token: token!,
      });
      message.success("Password has beem changed successfully");
      navigate("/login");
    } catch (e) {
      message.error("Something went wrong.");
    }
  };

  return (
    <>
      <h2 className={s.title}>Придумайте пароль</h2>
      <Form form={form} onFinish={handleSubmit} className={s.form}>
        <Form.Item name="password" className={clsx(s.formGroup, s.marginBottom)}>
          <TextField type="password" placeholder="Введите пароль" required fullWidth />
        </Form.Item>
        <Form.Item name="re-enter-password" className={s.formGroup}>
          <TextField type="password" placeholder="Повторите пароль" fullWidth required />
        </Form.Item>

        <Form.Item className={s.formGroup}>
          <Button type="submit" className={s.submit} fullWidth>
            Подтвердить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ResetPasswordPage;
