/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Divider,
  Form,
  GetRef,
  Input,
  InputRef,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService, CompanyRetrieve, PatchedBlockRequest } from "../../../../api";
import { PatchedCriteriaRequest } from "../../../../api/models/PatchedCriteriaRequest";
import useRegisterBlockStore from "../../../../store/registerBlockStore";
import { ChangeCRMModal } from "../../components/Company/modals/ChangeCRMModal";
import Status from "../../components/ui/Status/Status";
import s from "../../css/Company/CompanyDetails.module.scss";
import ExpandableText from "./ExpandableText";

type FormInstance<T> = GetRef<typeof Form<T>>;

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface DataType {
  key: React.Key;
  registerBlockType: string;
  registerBlockName: string;
  criteriaDescription: string;
  criteriaName: string;
  criteriaScenario: string;
  scoreModule: number;
  blockId: number;
}

interface EditableRowProps {
  index: number;
}

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType) => void;
  handleDelete: (key: React.Key, dataIndex: string) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const EditableCellComponent: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleDelete,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
      toggleEdit();
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const handleDeleteBlock = async () => {
    try {
      await ApiService.apiMainDeleteRegisterBlockList(record.blockId);
      handleDelete(record.key, dataIndex);
      message.success("Блок успешно удален.");
    } catch (error) {
      message.error("Ошибка при удалении блока.");
      console.error("Error deleting block:", error);
    }
  };

  const handleDeleteModule = async () => {
    try {
      await ApiService.apiMainDeleteCriteriaList(record.key as number);
      handleDelete(record.key, dataIndex);
      message.success("Модуль успешно удален.");
    } catch (error) {
      message.error("Ошибка при удалении модуля.");
      console.error("Error deleting module:", error);
    }
  };

  const handleDeleteScenario = async () => {
    try {
      const patchedCriteria: Partial<PatchedCriteriaRequest> = { scenario: "" };
      await ApiService.apiMainRegisterCriteriaPartialUpdate(record.key as number, patchedCriteria);
      handleSave({ ...record, criteriaScenario: "" });
      message.success("Сценарий успешно удален.");
    } catch (error) {
      message.error("Ошибка при удалении сценария.");
      console.error("Error deleting scenario:", error);
    }
  };

  let childNode = children;

  if (editable) {
    if (dataIndex === "criteriaName") {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} обязательно для заполнения.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className={s.editableCellValueWrap}
          onClick={toggleEdit}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleEdit();
            }
          }}
        >
          <span className={s.textContent}>{children}</span>
          <Popconfirm
            title="Это нельзя будет отменить"
            description="Удалить модуль?"
            onConfirm={(e: any) => {
              e.stopPropagation();
              handleDeleteModule();
            }}
            onCancel={(e: any) => {
              e.stopPropagation();
            }}
            okText="Удалить"
            cancelText="Отмена"
          >
            <DeleteOutlined className={s.deleteIcon} onClick={(e) => e.stopPropagation()} />
          </Popconfirm>
        </div>
      );
    } else if (dataIndex === "criteriaScenario") {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
      ) : (
        <div
          className={s.editableCellValueWrap}
          onClick={toggleEdit}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleEdit();
            }
          }}
        >
          <span className={s.textContent}>{children}</span>
          <Popconfirm
            title="Это нельзя будет отменить"
            description="Удалить сценарий?"
            onConfirm={(e: any) => {
              e.stopPropagation();
              handleDeleteScenario();
            }}
            onCancel={(e: any) => {
              e.stopPropagation();
            }}
            okText="Удалить"
            cancelText="Отмена"
          >
            <DeleteOutlined className={s.deleteIcon} onClick={(e) => e.stopPropagation()} />
          </Popconfirm>
        </div>
      );
    } else {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} обязательно для заполнения.`,
            },
          ]}
        >
          <Input.TextArea
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
      ) : (
        <div
          className={s.editableCellValueWrap}
          onClick={toggleEdit}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleEdit();
            }
          }}
        >
          <span className={s.textContent}>{children}</span>
          <Popconfirm
            title="Это нельзя будет отменить"
            description="Удалить блок?"
            onConfirm={(e: any) => {
              e.stopPropagation();
              handleDeleteBlock(); // Existing delete block handler
            }}
            onCancel={(e: any) => {
              e.stopPropagation();
            }}
            okText="Удалить"
            cancelText="Отмена"
          >
            <DeleteOutlined className={s.deleteIcon} onClick={(e) => e.stopPropagation()} />
          </Popconfirm>
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRowComponent: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const Details = () => {
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyRetrieve>();
  const navigate = useNavigate();
  const [changeCrmModalVisible, setChangeCrmModalVisible] = useState<boolean>(false);
  const [fetch, setFetch] = useState<number>(0);
  const [modifiedBlock, setModifiedBlock] = useState<
    { managerSetting: string; data: DataType[] }[]
  >([]);

  const loadCompany = async (id: any) => {
    try {
      const companyResult = await ApiService.apiAccountsCompanyRetrieve(id);
      if (companyResult.is_active === false) {
        navigate(`/company/create/${companyResult.id}`);
      }
      setCompany(companyResult);
    } catch (error) {
      console.error("Error loading company:", error);
    }
  };

  const loadData = useCallback(async () => {
    try {
      if (id) {
        const resultRegisterBlock = await ApiService.apiMainRegisterBlockListFilter(id);
        const registerCriteria = await ApiService.apiMainRegisterCriteriaList();
        if (resultRegisterBlock && registerCriteria) {
          // Сохранение отфильтрованных register blocks в Zustand
          useRegisterBlockStore.getState().setRegisterBlocks(resultRegisterBlock);

          const blocksByManagerSetting = resultRegisterBlock.reduce<
            Record<string, (typeof resultRegisterBlock)[0][]>
          >((acc, block) => {
            const { manager_setting } = block;
            if (!acc[manager_setting]) {
              acc[manager_setting] = [];
            }
            acc[manager_setting].push(block);
            return acc;
          }, {});

          const groupedData = Object.entries(blocksByManagerSetting).map(
            ([managerSetting, blocks]) => {
              const data = blocks.flatMap((block) => {
                const criteria = registerCriteria.filter((criteria) => criteria.block === block.id);

                if (criteria.length > 0) {
                  return criteria.map((criterion) => ({
                    key: criterion.id, // Используем id критерия в качестве ключа
                    registerBlockType: block.block_type,
                    registerBlockName: block.name,
                    criteriaDescription: criterion.description || "",
                    criteriaName: criterion.name || "",
                    criteriaScenario: criterion.scenario || "",
                    scoreModule: criterion.weight,
                    blockId: block.id,
                  }));
                }
                // Если критериев нет, создаём объект с пустыми полями
                return {
                  key: block.id,
                  registerBlockType: block.block_type,
                  registerBlockName: block.name,
                  criteriaDescription: "",
                  criteriaName: "",
                  criteriaScenario: "",
                  scoreModule: 0,
                  blockId: block.id,
                };
              });

              return {
                managerSetting,
                data,
              };
            },
          );

          setModifiedBlock(groupedData);
        }
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await loadCompany(id);
      await loadData();
    })();
  }, [id, loadData, navigate]);

  const components = {
    body: {
      row: EditableRowComponent,
      cell: EditableCellComponent,
    },
  };

  const handleSave = async (row: DataType) => {
    let targetManagerSettingIndex = -1;
    let previousRow: DataType | undefined;
    let targetDataIndex = -1;

    modifiedBlock.forEach((block, managerSettingIndex) => {
      const foundIndex = block.data.findIndex((item) => item.key === row.key);
      if (foundIndex !== -1) {
        targetManagerSettingIndex = managerSettingIndex;
        previousRow = block.data[foundIndex];
        targetDataIndex = foundIndex;
      }
    });

    if (!previousRow) {
      message.error("Исходные данные не найдены.");
      return;
    }

    const originalData = { ...previousRow };

    const patchedCriteria: Partial<PatchedCriteriaRequest> = {};
    if (row.criteriaName !== previousRow.criteriaName) {
      patchedCriteria.name = row.criteriaName;
    }
    if (row.criteriaScenario !== previousRow.criteriaScenario) {
      patchedCriteria.scenario = row.criteriaScenario;
    }
    if (row.criteriaDescription !== previousRow.criteriaDescription) {
      patchedCriteria.description = row.criteriaDescription;
    }
    if (row.scoreModule !== previousRow.scoreModule) {
      patchedCriteria.weight = row.scoreModule;
    }
    if (row.blockId !== previousRow.blockId) {
      patchedCriteria.block = row.blockId;
    }

    const patchedBlock: Partial<PatchedBlockRequest> = {};
    if (row.registerBlockName !== previousRow.registerBlockName) {
      patchedBlock.name = row.registerBlockName;
      patchedBlock.block_type = row.registerBlockType;
    }

    setModifiedBlock((prevData) => {
      const newData = [...prevData];
      if (targetManagerSettingIndex !== -1 && targetDataIndex !== -1) {
        const updatedRow = {
          ...newData[targetManagerSettingIndex].data[targetDataIndex],
          ...row,
        };
        newData[targetManagerSettingIndex].data.splice(targetDataIndex, 1, updatedRow);
      }
      return newData;
    });

    try {
      if (Object.keys(patchedCriteria).length > 0) {
        await ApiService.apiMainRegisterCriteriaPartialUpdate(row.key as number, patchedCriteria);
      }
      if (Object.keys(patchedBlock).length > 0) {
        await ApiService.apiMainEditRegisterBlockList(row.blockId, patchedBlock);
      }
      message.success("Строка успешно сохранена");
    } catch (error) {
      console.error("Error saving row:", error);
      message.error("Ошибка при сохранении строки");

      // Возврат к исходным данным в случае ошибки
      setModifiedBlock((prevData) => {
        const newData = [...prevData];
        if (targetManagerSettingIndex !== -1 && targetDataIndex !== -1) {
          newData[targetManagerSettingIndex].data.splice(targetDataIndex, 1, originalData);
        }
        return newData;
      });
    }
  };
  const handleDelete = (key: React.Key, dataIndex: string) => {
    if (dataIndex === "criteriaName") {
      setModifiedBlock((prevData) =>
        prevData.map((block) => {
          // Проверяем, содержит ли текущий блок элемент с заданным key
          const isBlockContainingKey = block.data.some((item) => item.key === key);

          if (isBlockContainingKey) {
            // Обновляем только те элементы, которые соответствуют ключу
            const updatedData = block.data.map((item) => {
              if (item.key === key) {
                return {
                  ...item,
                  criteriaDescription: "",
                  criteriaName: "",
                  criteriaScenario: "",
                  scoreModule: 0,
                };
              }
              return item;
            });

            return {
              ...block,
              data: updatedData,
            };
          }

          // Если блок не содержит нужного элемента, возвращаем его без изменений
          return block;
        }),
      );
    } else {
      setModifiedBlock((prevData) =>
        prevData.map((block) => ({
          ...block,
          data: block.data.filter((item) => item.key !== key),
        })),
      );
    }
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Соблюдение",
      dataIndex: "registerBlockType",
      editable: false,
      width: "12%",
    },
    {
      title: "Блоки",
      dataIndex: "registerBlockName",
      editable: true,
      width: "12%",
    },
    {
      title: "Модули",
      dataIndex: "criteriaName",
      editable: true,
      width: "12%",
    },
    {
      title: "Сценарий",
      dataIndex: "criteriaScenario",
      editable: true,
      width: "20%",
      render: (text: string) => <ExpandableText text={text} />,
    },
    {
      title: "Вес модуля",
      dataIndex: "scoreModule",
      editable: true,
      width: "7%",
    },

    {
      title: "Критерии оценки",
      dataIndex: "criteriaDescription",
      editable: true,
      width: "20%",

      render: (text: string) => <ExpandableText text={text} />,
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        handleDelete,
      }),
    };
  });

  return (
    <>
      <Breadcrumb
        className={s.breadcrumb}
        items={[
          {
            title: <a href="/">Все компании</a>,
          },
          {
            title: <a href={`/company/create/${id}`}>{company?.name}</a>,
          },
        ]}
      />
      <Row justify="space-between">
        <h3 className={s.name}>
          {company?.name} <EditOutlined onClick={() => navigate(`/company/create/${id}`)} />
        </h3>
        <Space>
          <div className={s.subscription}>
            Подписка: c {dayjs(company?.package_permission?.date_start).format("DD.MM.yyyy")} по{" "}
            {dayjs(company?.package_permission?.date_end).format("DD.MM.yyyy")}
          </div>
          <Status state={company?.is_active} />
        </Space>
      </Row>
      <div className={s.label}>
        Владелец: <span>{company?.owner_name}</span>
      </div>
      <div className={s.label}>
        Количество сотрудников:{" "}
        <span>
          <a href={`/company/${id}/employees`}>{company?.staff_count}</a>
        </span>
      </div>
      <div className={s.label}>
        Количество подразделений:{" "}
        <span>
          <a href={`/company/${id}/departments`}>{company?.company_departments.length}</a>
        </span>
      </div>
      <div className={s.label}>
        e-mail владельца: <span>{company?.owner_email}</span>
      </div>
      <div className={s.label}>
        Номер телефона: <span>{company?.owner_phone}</span>
      </div>
      <Divider />
      <div className={s.label}>
        Тип CRM: <span>{company?.crm_type}</span>
      </div>
      <div className={s.label}>
        Вебхук/Токен:{" "}
        <span>
          <EditOutlined onClick={() => setChangeCrmModalVisible(true)} />
        </span>
      </div>
      <Divider />
      <h3 className={s.name}>
        Загруженный скрипт <EditOutlined onClick={() => console.log("edit")} />
      </h3>
      <Tabs defaultActiveKey="0">
        {modifiedBlock.map(({ managerSetting, data }, index) => (
          <Tabs.TabPane tab={`Manager Setting: ${managerSetting}`} key={index.toString()}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={data}
              columns={columns as ColumnTypes}
              scroll={{ x: 1200, y: 630 }}
              tableLayout="fixed"
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <ChangeCRMModal
        company_id={id}
        visible={changeCrmModalVisible}
        setVisible={setChangeCrmModalVisible}
        fetch={fetch}
        setFetch={setFetch}
        company={company}
      />
    </>
  );
};
