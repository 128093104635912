/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import s from "./Cases.module.scss";

export const Cases = () => {
  const { t, i18n } = useTranslation();

  const casesItems = [
    {
      img: "images/landingPage/cases1.png",
      title: t("Landing.case.firstcasetitle"),
      subtitle:
        i18n.language === "ru"
          ? "Реализовано <span><b>26 кейсов</b></span>"
          : "<span><b>26 оқушы нәтижеге</b></span> жеткізілді",
      link: "#",
    },
    {
      img: "images/landingPage/cases2.png",
      title: t("Landing.case.secondcasetitle"),
      subtitle:
        i18n.language === "ru"
          ? "Реализовано <span><b>4 кейса</b></span>"
          : "<span><b>4 оқушы нәтижеге </b></span>жеткізілді",
      link: "#",
    },
    {
      img: "images/landingPage/cases3.png",
      title: t("Landing.case.thirdcasetitle"),
      subtitle:
        i18n.language === "ru"
          ? "Реализовано <span><b>10 кейсов</b></span>"
          : "<span><b>10 оқушы нәтижеге</b></span> жеткізілді",
      link: "#",
    },
  ];

  return (
    <div className={s.wrapper} id="cases">
      <div className={s.header}>
        <span className={s.dot} />
        <span className={s.title}>{t("Landing.header.cases")}</span>
      </div>
      {i18n.language === "ru" && (
        <h3 className={s.mainTitle}>
          Наша компания реализовала <span>70+ кейсов</span>
        </h3>
      )}
      {i18n.language === "kz" && (
        <h3 className={s.mainTitle}>
          Біздің компания <span>70-тен аса</span> оқушыларымызды жүзеге асырдық
        </h3>
      )}
      <div className={s.cases}>
        {casesItems.map((caseItem, index) => (
          <div className={s.casesItem} key={index}>
            <a href="#" className={s.casesItemLink}>
              <div
                className={s.casesItemImg}
                style={{ backgroundImage: `url(${caseItem.img})`, marginTop: "-62px" }}
              />
              <h3 className={s.casesItemTitle}>{caseItem.title}</h3>
              <p
                className={s.casesItemSubtitle}
                dangerouslySetInnerHTML={{ __html: caseItem.subtitle }}
              />
              <img className={s.casesItemIcon} src="images/landingPage/arrow.svg" alt="" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
