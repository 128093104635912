/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */

/**
 * * `Administrator` - Administrator
 * * `Owner` - Owner
 * * `ROP` - ROP
 * * `Manager` - Manager
 */
export enum RoleEnum {
  ADMINISTRATOR = "Administrator",
  OWNER = "Owner",
  ROP = "ROP",
  MANAGER = "Manager",
}
