import { Navigate, RouteObject } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import { Analytics } from "../../pages/Owner/Analytics";
import { Employees } from "../../pages/Owner/Employees";
import { Reports } from "../../pages/Owner/Reports";
import { ComplianceSales } from "../../pages/Owner/Reports/ComplianceSales/ComplianceSales";
import { ComplianceScript } from "../../pages/Owner/Reports/ComplianceScript";
import { ManagerProgress } from "../../pages/Owner/Reports/ManagerProgress";
import { Rnp } from "../../pages/Owner/Rnp";
import { UserManual } from "../../pages/Owner/UserManual";

export const OwnerRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/login",
        element: <Navigate to="/" replace />,
      },
      {
        index: true,
        element: <UserManual />,
      },
      {
        path: "/reports",
        element: <Reports />,
      },
      {
        path: "/reports/script/:id",
        element: <ComplianceScript />,
      },
      {
        path: "/reports/postgres/:id",
        element: <ManagerProgress />,
      },
      {
        path: "/reports/sales/:id",
        element: <ComplianceSales />,
      },

      {
        path: "/rnp",
        element: <Rnp />,
      },
      {
        path: "/employees",
        element: <Employees />,
      },
      {
        path: "/analytics",
        element: <Analytics />,
      },
    ],
  },
];
