/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { MenuOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { CustomStyledModal } from "../../../../components/ui/CustomStyledModal/CustomStyledModal";
import InstagramIconBased from "../../../../icons/InstagramIconBase";
import LogoIcon from "../../../../icons/LogoIcon";
import { Switch } from "../../../../layouts/MainLayout/Sidebar";
import { AppRoutesName } from "../../../../router/AppRoutesName";
import s from "./Header.module.scss";
import InstagramIcon from "./icons/Instagram";
import MenuIcon from "./icons/MenuIcon";

type Props = {
  hideMenuLinks?: boolean;
};

export const Header: FC<Props> = ({ hideMenuLinks }) => {
  const { t, i18n } = useTranslation();

  const menuLinks = [
    {
      label: t("Landing.header.main"),
      link: "#",
      key: "1",
    },
    {
      label: t("Landing.header.aboutus"),
      link: "#aboutUs",
      key: "2",
    },
    {
      label: t("Landing.header.cases"),
      link: "#cases",
      key: "3",
    },
    {
      label: t("Landing.header.ourproducts"),
      link: "#results",
      key: "4",
    },
    // {
    //   label: "Статьи",
    //   link: "#",
    //   key: "5",
    // },
  ];
  const location = useLocation();

  const getLinkInfo = () => {
    if (location.pathname === "/login") {
      return { label: t("Landing.returnsite"), link: "/landing" };
    }
    if (location.pathname === "/landing") {
      return { label: t("Landing.toplatform"), link: "/login" };
    }
    return null;
  };
  const linkInfo = getLinkInfo();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      const defaultLanguage = "ru";
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem("language", defaultLanguage);
    }
  }, [i18n]);
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const handleToggleLanguage = () => {
    const newLanguage = i18n.language === "ru" ? "kz" : "ru";
    setIsToggled(!isToggled);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };
  return (
    <div className={s.wrapper}>
      <a href={AppRoutesName.landingPage}>
        <LogoIcon />
      </a>
      <div style={{ display: "flex", alignItems: "center", columnGap: "15px" }}>
        {!hideMenuLinks ? (
          <div className={s.menu}>
            {menuLinks.map((item, index) => (
              <div key={index}>
                <a className={s.menuItemLink} href={item.link}>
                  {item.label}
                </a>
              </div>
            ))}
            <div className={s.menuItem}>
              <a className={s.menuItemLink} href="mailto:senimsatu@gmail.com">
                <p
                  style={{
                    border: "1px solid #FFD966",
                    borderRadius: "48px",
                    padding: "10px 15px",
                    width: i18n.language === "ru" ? "200px" : "150px",
                    whiteSpace: "wrap",
                  }}
                >
                  {t("Landing.header.career")}
                </p>
              </a>
            </div>
            <Switch
              className={s.switch}
              onClick={handleToggleLanguage}
              toggled={isToggled}
              label={isToggled ? "ru" : "kz"}
            />
          </div>
        ) : null}
        {linkInfo && (
          <a href={linkInfo.link} className={s.goToPlatform}>
            {linkInfo.label}
          </a>
        )}
      </div>
      <div className={s.logo}>
        <div className={s.logo}>
          <div className={s.socials}>
            {/* <a className={s.social} href="#/">
              <TelegramIcon />
            </a>
            <a className={s.social} href="#/">
              <WhatsappIcon />
            </a> */}
            <a
              className={s.social}
              target="_blank"
              href="https://www.instagram.com/satoo.sales?igsh=czlya21iOHZ0OHk3"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </div>
          <div
            onClick={toggleMenu}
            style={{ marginLeft: "20px" }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleMenu();
              }
            }}
            aria-label="Edit item"
          >
            <MenuIcon />
          </div>
        </div>
      </div>

      {menuVisible && (
        <CustomStyledModal open={menuVisible} onCancel={toggleMenu} footer={null}>
          <div className={s.burger}>
            <div>
              <a href={AppRoutesName.landingPage}>
                <LogoIcon color="white" style={{ marginBottom: "50px" }} />
              </a>
              {menuLinks.map((item, index) => (
                <div className={s.menuItem} key={index}>
                  <a
                    className={`${s.menuItemLink} ${item.label === "О нас" ? s.blueLink : ""}`}
                    href={item.link}
                    onClick={toggleMenu}
                  >
                    {item.label.toUpperCase()}
                  </a>
                </div>
              ))}
            </div>
            <div className={s.wrap}>
              <div className={s.socials}>
                {/* <a className={s.socialBased} href="#/">
                  <TelegramIconBased />
                </a>
                <a className={s.socialBased} href="#/">
                  <WhatsappIconBased />
                </a> */}
                <a
                  className={s.socialBased}
                  target="_blank"
                  href="https://www.instagram.com/satoo.sales?igsh=czlya21iOHZ0OHk3"
                  rel="noreferrer"
                >
                  <InstagramIconBased />
                </a>
              </div>
              <div className={s.languageSwitch}>
                <span
                  className={`${i18n.language === "kz" ? s.activeLanguage : s.inactiveLanguage}`}
                  onClick={() => i18n.changeLanguage("kz")}
                >
                  kz
                </span>
                <span className={s.separator}>|</span>
                <span
                  className={`${i18n.language === "ru" ? s.activeLanguage : s.inactiveLanguage}`}
                  onClick={() => i18n.changeLanguage("ru")}
                >
                  ru
                </span>
              </div>
            </div>
          </div>
        </CustomStyledModal>
      )}
    </div>
  );
};
