import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import YouTube from "react-youtube";
import { ApiService, Training } from "../../../api";
import s from "./TrainingBase.module.scss";
import { ControlPanel } from "./sections/ControlPanel";

const getVideoId = (url: string): string | null => {
  const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^\s&]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

interface Item {
  videoId: string;
  title: string;
  fileUrl: string;
  description: string;
}

const VideoPlayer: React.FC<Item> = React.memo(({ videoId, description, title, fileUrl }) => {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      mute: 0,
      controls: 0,
      rel: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      playsinline: 1,
      enablejsapi: 1,
    },
  };

  return (
    <LazyLoad height={200} offset={100}>
      <div className={s.video}>
        <YouTube
          videoId={videoId}
          opts={opts}
          onError={(e: any) => console.error(`Error loading video "${title}":`, e)}
          onReady={() => console.log(`Video "${title}" is ready`)}
        />
        <div className={s.videoInfo}>
          <p className={s.title}>Тема: {title}</p>
          <p className={s.title}>Тема: {description}</p>
          <a href={fileUrl} download>
            <button className={s.downloadButton}>Скачать PDF</button>
          </a>
        </div>
      </div>
    </LazyLoad>
  );
});

const TrainingBase: React.FC = () => {
  const [trainingBaseData, setTrainingBaseData] = useState<Training[]>([]);

  const fetchTrainingBase = async () => {
    try {
      const data = await ApiService.apiMainTrainingList();
      setTrainingBaseData(data);
    } catch (e) {
      console.error("Error fetching training data:", e);
    }
  };

  useEffect(() => {
    fetchTrainingBase();
  }, []);

  return (
    <div className={s.root}>
      <ControlPanel trainingBaseData={trainingBaseData} fetchTrainingBase={fetchTrainingBase} />
      <div className={s.videoList}>
        {trainingBaseData.map((training) => {
          const videoId = getVideoId(training.video_url);
          if (!videoId) {
            return <p key={training.id}>Invalid video URL</p>;
          }
          return (
            <VideoPlayer
              fileUrl={`${training.pdf}`}
              key={training.id}
              videoId={videoId}
              title={training.title}
              description={training.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export { TrainingBase };
