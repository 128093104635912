import { create } from "zustand";

type FilterState = {
  filterValues: any;
  setFilterValues: (values: any) => void;
};

const useFilterStore = create<FilterState>((set) => ({
  filterValues: {},
  setFilterValues: (values: any) => set({ filterValues: values }),
}));

export default useFilterStore;
