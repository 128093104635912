/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Select, Table, TableColumnsType } from "antd";
import { Chart, registerables } from "chart.js";
import clsx from "clsx";
import React from "react";
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { Item1Icon } from "./icons/Item1Icon";
import { Item2Icon } from "./icons/item2Icon";
import { Item3Icon } from "./icons/item3Icon";
import { Item4Icon } from "./icons/item4Icon";
import s from "./ManagerProgress.module.scss";

Chart.register(...registerables);

interface DataType {
  key: React.Key;
  characteristic: string;
  status: string;
  count: string;
}

const ManagerProgress = () => {
  const navigate = useNavigate();

  const handleSelect = (value: string) => {
    if (value === "sales") {
      navigate("/reports/sales/3434");
    }
    if (value === "script") {
      navigate("/reports/script/3434");
    }
  };

  const ChartData = {
    labels: [],
    datasets: [
      {
        label: "пройдены тренировки",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(0,0,0)",
        tension: 0.1,
      },
      {
        label: "собл. скрипта",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "#FFD966",
        tension: 0.1,
      },
      {
        label: "собл. техник продаж",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "#03AED2",
        tension: 0.1,
      },
    ],
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "Аналитика по клиентам",
      children: [
        {
          title: "Характеристика",
          dataIndex: "characteristic",
          key: "characteristic",
        },
        {
          title: "Статус",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Количество",
          dataIndex: "count",
          key: "count",
        },
      ],
    },
  ];
  const TableData: DataType[] = [
    {
      key: 1,
      characteristic: "Tessst",
      count: "54",
      status: "test",
    },
  ];
  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.filter}>
          <Select className={s.select} placeholder="Выберите период">
            <Select.Option value="monthly">Месячный</Select.Option>
          </Select>
          <Select
            className={s.select}
            placeholder="Выберите"
            defaultValue="postgres"
            onSelect={handleSelect}
          >
            <Select.Option value="sales">Соблюдение техник продаж</Select.Option>
            <Select.Option value="script">Соблюдение скрипта</Select.Option>
            <Select.Option value="postgres">Прогресс менеджера</Select.Option>
          </Select>
        </div>
        <Button>Экспорт </Button>
      </div>
      <Breadcrumb
        className={s.breadcrumb}
        items={[
          {
            title: <a href="/reports">Общий</a>,
          },
          {
            title: <a href="#">Жакупова Айдана</a>,
          },
        ]}
      />
      <div className={s.items}>
        <div className={clsx(s.item, s.item1)}>
          <Item1Icon />
          <div className={s.info}>
            <span className={s.label}>13/15</span>
            <span className={s.title}>Пройдено тренировок</span>
          </div>
        </div>
        <div className={clsx(s.item, s.item2)}>
          <Item2Icon />
          <div className={s.info}>
            <span className={s.label}>50</span>
            <span className={s.title}>Совершенных сделок</span>
          </div>
        </div>
        <div className={clsx(s.item, s.item3)}>
          <Item3Icon />
          <div className={s.info}>
            <span className={s.label}>8</span>
            <span className={s.title}>Просроченных сделок</span>
          </div>
        </div>
        <div className={clsx(s.item, s.item4)}>
          <Item4Icon />
          <div className={s.info}>
            <span className={s.label}>12</span>
            <span className={s.title}>Сделок без задач</span>
          </div>
        </div>
      </div>
      <div className={s.chart}>
        <Line data={ChartData} />
      </div>
      <div className={s.table}>
        <Table columns={columns} dataSource={TableData} bordered size="middle" />
      </div>
    </div>
  );
};
export { ManagerProgress };
