import { Col, Progress, Table } from "antd";
import "./Reports.module.scss";

const TableData = [
  {
    key: 1,
    employee: <a href="/reports/script/454">Жакупова Айдана</a>,
    script: <Progress percent={50} showInfo={false} className="progress-bar" />,
    compliance: <Progress percent={60} showInfo={false} className="progress-bar" />,
  },
];

const Reports = () => {
  return (
    <Col>
      <div className="excel-table">
        <Table
          bordered
          showHeader
          dataSource={TableData}
          pagination={false}
          rowKey="key"
          scroll={{ x: 380 }}
        >
          <Table.Column
            dataIndex="employee"
            title="Сотрудник"
            fixed="left"
            className="progress-bar"
          />
          <Table.Column dataIndex="script" title="Скрипт" className="progress-bar" />
          <Table.Column dataIndex="compliance" title="Соответствие" className="progress-bar" />
        </Table>
      </div>
    </Col>
  );
};

export { Reports };
