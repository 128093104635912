/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import { DownOutlined, EditOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  GetRef,
  Input,
  InputRef,
  Progress,
  Row,
  Select,
  Table,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ApiService,
  DecompositionByCompany,
  GetDepartmentsWithEmployeesDepartment,
} from "../../../api";
import { Column } from "../../../api/models/DecompositionByCompany";
import { getCurrentCompanyId } from "../../../authHelper";
import useFilterStore from "../../../store/filterStore";
import { AddSectionModal } from "./AddSectionModal";
import "./Table.css";

const { MonthPicker } = DatePicker;

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  date: string;
  [key: string]: any;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item, key: string, value: any) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  date: string;
  [key: string]: any;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

interface PatchedDecompositionRequest {
  columns: Array<{
    position: number;
    field_id: string;
    column_name: string;
  }>;
}

const Rnp = () => {
  const { filterValues, setFilterValues } = useFilterStore();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [addSectionModalVisible, setAddSectionModalVisible] = useState<boolean>(false);
  const [departmentsWithManagers, setDepartmentsWithManagers] = useState<
    GetDepartmentsWithEmployeesDepartment[]
  >([]);
  const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [fetch, setFetch] = useState<number>(0);
  const [progress, setProgress] = useState<any[]>([]);
  const [conversion, setConversion] = useState<any[]>([]);
  const [availableStatuses, setAvailableStatuses] = useState<any[]>([]);
  const [pipelines, setPipelines] = useState<any[]>([]);
  const [decompositionId, setDecompositionId] = useState<string | null>(null);
  const companyId = getCurrentCompanyId();

  const loadConversionAndProgress = async () => {
    try {
      const conversionData = await ApiService.apiMainRnpGetConversionsByCompanyCreate(
        companyId,
        filterValues?.department,
        filterValues?.manager,
      );

      const progressData = await ApiService.apiMainRnpGetProgressByCompanyCreate(
        companyId,
        filterValues?.department,
        filterValues?.manager,
      );

      const conversionFormatted = conversionData.map((x: any) => ({
        name: `${x.from_column?.column_name} -> ${x.to_column?.column_name}`,
        plan: x.plan_conversion,
        fact: x.fact_conversion,
      }));

      const progressFormatted = progressData.map((x: any) => ({
        name: x.column_name,
        fact: x.total_fact,
        plan: x.total_plan,
        progress: x.progress_percent,
      }));

      setConversion(conversionFormatted);
      setProgress(progressFormatted);
    } catch (error) {
      message.error("Не удалось загрузить данные о конверсии и прогрессе.");
    }
  };

  const loadAvailableStatuses = async (pipelineId: any) => {
    try {
      const statuses = await ApiService.apiMainPipelineStatusList(companyId!, pipelineId);
      setAvailableStatuses(statuses);
    } catch (error) {
      console.error("Ошибка при загрузке доступных статусов:", error);
      message.error("Не удалось загрузить доступные статусы.");
    }
  };

  const loadPipelines = async () => {
    try {
      const result = await ApiService.apiMainCrmPipelineRetrieve(companyId!);
      setPipelines(result);
    } catch (error) {
      console.error("Ошибка при загрузке воронок:", error);
      message.error("Не удалось загрузить воронки.");
    }
  };

  useEffect(() => {
    loadPipelines();
  }, [fetch, companyId]);

  const handlePipelineChange = (pipelineId: string) => {
    loadAvailableStatuses(pipelineId);
  };

  useEffect(() => {
    if (filterValues?.pipeline) {
      loadAvailableStatuses(filterValues.pipeline);
    }
  }, [filterValues, fetch, companyId]);

  const loadDepartmentsWithManagers = async () => {
    try {
      const result = await ApiService.apiAccountsCompanyGetDepartmentsWithEmployeesList();
      setDepartmentsWithManagers(result);
    } catch (error) {
      console.error("Error loading departments with managers:", error);
      message.error("Не удалось загрузить подразделения и менеджеров.");
    }
  };

  useEffect(() => {
    (async () => {
      await loadDepartmentsWithManagers();
    })();
  }, []);

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const handleUpdate = async (record: any, dataIndex: any, value: any) => {
    try {
      const [status_id, field] = dataIndex.split("_");
      const manager_id = form.getFieldValue("manager_id");
      const decomposition_date = form.getFieldValue("decomposition_date");

      await ApiService.apiMainRnpUpdateRnpValueCreate({
        manager_id,
        field,
        status_id,
        date: record.key,
        value,
        decomposition_date: dayjs(decomposition_date).format("YYYY-MM-DD"),
      });

      setFetch(fetch + 1);
      message.success("Значение успешно обновлено.");
    } catch (error) {
      console.error("Error updating record:", error);
      message.error("Не удалось обновить значение.");
    }
  };

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleUpdate(record, dataIndex as string, values[dataIndex]);
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
          className="editable-cell-value-wrap"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              toggleEdit();
            }
          }}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const addSection = async (sections: { field_id: string; column_name: string }[]) => {
    try {
      if (!decompositionId) {
        message.error("Не удалось определить идентификатор декомпозиции.");
        return;
      }

      const existingSections = columns.filter((col) => col.title !== "Общее");
      const currentPosition = existingSections.length;

      const columnsToAdd = sections.map((section, index) => ({
        position: currentPosition + index + 1,
        field_id: section.field_id,
        column_name: section.column_name,
      }));

      const requestBody: PatchedDecompositionRequest = {
        columns: columnsToAdd,
      };

      const updatedDecomposition = await ApiService.apiMainRnpPartialUpdate(
        decompositionId.toString(),
        requestBody,
      );

      const newCols = updatedDecomposition.map((x: any) => ({
        title: x.column_name,
        children: [
          {
            title: (
              <span>
                План <EditOutlined style={{ marginLeft: 4, cursor: "pointer" }} />
              </span>
            ),
            dataIndex: `${x.field_id}_plan`,
            key: `${x.field_id}_plan`,
            width: 150,
            editable: true,
          },
          {
            title: "Факт",
            dataIndex: `${x.field_id}_fact`,
            key: `${x.field_id}_fact`,
            width: 150,
          },
          {
            title: "Разница",
            dataIndex: `${x.field_id}_difference`,
            key: `${x.field_id}_difference`,
            width: 150,
          },
        ],
      }));

      setColumns((prevColumns: any[]) => {
        const baseColumn = prevColumns.find((col) => col.title === "Общее");
        const otherColumns = prevColumns.filter((col) => col.title !== "Общее");
        return [baseColumn, ...otherColumns, ...newCols];
      });

      message.success("Секции успешно добавлены.");
      setAddSectionModalVisible(false);
    } catch (error) {
      console.error("Ошибка при добавлении секций:", error);
      message.error("Не удалось добавить секции.");
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const result: DecompositionByCompany[] =
        await ApiService.apiMainRnpGetDecompositionsByCompanyCreate(
          companyId,
          filterValues?.department,
          filterValues?.manager,
        );
      console.log(result);
      if (!result || result.length === 0) {
        setColumns([]);
        setDataSource([]);
        setDecompositionId(null);
        setIsLoading(false);
        return;
      }

      setDecompositionId(result[0].id);

      const newCols = result[0].columns?.map((x: Column) => ({
        title: x.column_name,
        children: [
          {
            title: "План",
            dataIndex: `${x.id}_plan`,
            key: `${x.id}_plan`,
            width: 120,
            editable: true,
          },
          {
            title: "Факт",
            dataIndex: `${x.id}_fact`,
            key: `${x.id}_fact`,
            width: 120,
          },
          {
            title: "Разница",
            dataIndex: `${x.id}_difference`,
            key: `${x.id}_difference`,
            width: 120,
          },
        ],
      }));

      // Найдём колонку с field_id "142"
      const field142Index = result[0]?.columns?.findIndex((col) => col.field_id === "142");
      if (field142Index === -1) {
        message.error('Не удалось найти колонку с field_id "142".');
        setIsLoading(false);
        return;
      }

      // Проверяем, что перед колонкой "142" есть ещё одна колонка
      if (field142Index === 0) {
        message.error(
          'Колонка с field_id "142" является первой и перед ней нет предыдущей колонки.',
        );
        setIsLoading(false);
        return;
      }

      // Определяем колонку перед "142"
      const field55135218 = result[0].columns[field142Index - 1];
      const field142 = result[0].columns[field142Index];

      // Проверка наличия необходимых колонок
      console.log("Колонка field55135218:", field55135218);
      console.log("Колонка field142:", field142);

      // Обработка колонок для редактирования
      const defaultColumns = newCols?.map((col: any) => {
        if (!col.children) {
          return col;
        }
        const editableChildren = col.children.map((childCol: any) => {
          const isPlanColumn = childCol.title.toLowerCase() === "план";
          if (isPlanColumn) {
            return {
              ...childCol,
              editable: true,
              onCell: (record: DataType) => ({
                record,
                editable: true,
                dataIndex: childCol.dataIndex as keyof DataType,
                title: childCol.title,
                handleUpdate,
              }),
            };
          }
          return childCol;
        });

        return {
          ...col,
          children: editableChildren,
        };
      });

      // Добавляем колонку "Ср. чек"
      const columnsList = [
        {
          title: "Общее",
          dataIndex: "date",
          key: "date",
          width: 120,
          fixed: "left",
        },
        ...(defaultColumns ?? []),
        {
          title: "Ср. чек",
          dataIndex: "average_check",
          key: "average_check",
          width: 150,
          render: (text: any, record: DataType) => {
            const fact142 = record[`${field142.id}_fact`];
            const fact55135218 = record[`${field55135218.id}_fact`];
            if (!fact55135218 || fact55135218 === 0) return 0;
            return (fact142 / fact55135218).toFixed(2);
          },
        },
      ];

      setColumns(columnsList);

      const dates = [
        ...new Set(
          result[0]?.columns?.flatMap((column: any) =>
            column.decomposition_column.map((day: any) => day.date),
          ),
        ),
      ];

      const tableData = dates.map((date: any) => {
        const row: any = { key: dayjs(date).format("DD.MM"), date: dayjs(date).format("DD.MM") };

        result[0]?.columns?.forEach((column: any) => {
          const dayData = column.decomposition_column.find((day: any) => day.date === date) || {};
          row[`${column.id}_plan`] = dayData.plan || 0;
          row[`${column.id}_fact`] = dayData.fact || 0;
          row[`${column.id}_difference`] = dayData.difference || 0;
        });

        // Рассчитываем средний чек для строки
        const fact142 = row[`${field142.id}_fact`] || 0;
        const fact55135218 = row[`${field55135218.id}_fact`] || 0;
        row.average_check = fact55135218 !== 0 ? (fact142 / fact55135218).toFixed(2) : 0;

        return row;
      });

      const totalRow: {
        key: string;
        date: string;
        [key: string]: any;
      } = {
        key: "total",
        date: "Выполнение",
      };
      result[0]?.columns?.forEach((column: any) => {
        totalRow[`${column.id}_plan`] = column.total_plan || 0;
        totalRow[`${column.id}_fact`] = column.total_fact || 0;
        totalRow[`${column.id}_difference`] = column.total_difference || 0;
      });

      // Рассчитываем средний чек для итоговой строки
      const total_fact142 = result[0]?.columns?.reduce(
        (acc: number, col: any) => acc + (col.total_fact || 0),
        0,
      );
      const total_fact55135218 = result[0]?.columns?.reduce(
        (acc: number, col: any) => acc + (col.total_fact55135218 || 0),
        0,
      );
      totalRow.average_check =
        total_fact55135218 !== 0 ? (total_fact142 / total_fact55135218).toFixed(2) : 0;

      setDataSource([totalRow, ...tableData]);
    } catch (error) {
      console.error(error);
      message.error("Не удалось загрузить данные.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    loadConversionAndProgress();
  }, [filterValues, fetch]);

  const handleDepartmentChange = (value: any) => {
    const department = departmentsWithManagers.find((x: any) => x.id === value);

    const managers =
      department?.department_employees.map((y: any) => ({
        value: y.id,
        title: y.full_name,
      })) ?? [];
    setSelectedManagers(managers);

    setFilterValues({
      ...filterValues,
      department: value,
      manager: null,
    });

    form.setFieldsValue({
      manager_id: null,
    });
  };

  const [open, setOpen] = useState(false);

  const handleOpenChange = () => {
    setOpen(!open);
  };

  return (
    <>
      <Form layout="horizontal" form={form}>
        <Row gutter={16} style={{ marginBottom: "2em" }}>
          <Col>
            <Form.Item
              style={{ marginBottom: "10px" }}
              name="decomposition_date"
              initialValue={dayjs()}
            >
              <MonthPicker
                suffixIcon={
                  open ? <UpOutlined className="rotate" /> : <DownOutlined className="rotate" />
                }
                onOpenChange={handleOpenChange}
                style={{ borderRadius: "47px", width: "160px" }}
                format="MMMM, YYYY"
                onChange={(value: any) =>
                  setFilterValues({
                    ...filterValues,
                    date: dayjs(value).format("MMMM, YYYY"),
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Подразделение" style={{ marginBottom: "10px" }}>
              <Select onChange={handleDepartmentChange} style={{ width: 200 }}>
                {departmentsWithManagers.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Менеджер" style={{ marginBottom: 0 }} name="manager_id">
              <Select
                style={{ width: 200 }}
                onChange={(value: any) =>
                  setFilterValues({
                    ...filterValues,
                    manager: value,
                  })
                }
              >
                {selectedManagers.map((x) => (
                  <Select.Option key={x.value} value={x.value}>
                    {x.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row gutter={[16, 16]} wrap>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="excel-table">
            <Table
              showHeader={false}
              title={() => <b>Конверсия</b>}
              bordered
              dataSource={conversion}
              pagination={false}
              scroll={{ x: 280 }}
            >
              <Table.Column dataIndex="name" />
              <Table.Column dataIndex="plan" />
              <Table.Column dataIndex="fact" />
            </Table>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="excel-table">
            <Table bordered showHeader dataSource={progress} pagination={false} scroll={{ x: 280 }}>
              <Table.Column dataIndex="name" fixed="left" width={100} />
              <Table.Column dataIndex="plan" title="План" width={80} />
              <Table.Column
                dataIndex="progress"
                title="%"
                render={(progress: any) => `${progress}%`}
                width={80}
              />
              <Table.Column dataIndex="fact" title="Выполнено" width={100} />
              <Table.Column
                dataIndex="progress"
                title="Прогресс"
                render={(progress: any) => (
                  <Progress percent={progress} showInfo={false} status="normal" />
                )}
                width={100}
              />
            </Table>
          </div>
        </Col>
      </Row>
      <div className="excel-table">
        <Table
          title={() => (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              // onClick={() => setAddSectionModalVisible(true)}
            >
              Добавить секцию
            </Button>
          )}
          scroll={{ x: 280 }}
          loading={isLoading}
          bordered
          style={{ marginTop: "2.5em" }}
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          pagination={false}
        />
        <AddSectionModal
          visible={addSectionModalVisible}
          setVisible={setAddSectionModalVisible}
          addSection={addSection}
          handlePipelineChange={handlePipelineChange}
          availableStatuses={availableStatuses}
          pipelines={pipelines}
        />
      </div>
    </>
  );
};

export { Rnp };
