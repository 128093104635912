import { Resource } from "i18next";
import en from "./Resources.en.json";
import kz from "./Resources.kk.json";
import ru from "./Resources.ru.json";

export const Resources: Resource = {
  en: { translation: en },
  ru: { translation: ru },
  kz: { translation: kz },
};
