import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../../pages/LandingPage/sections/Header/Header";
import s from "./LoginLayout.module.scss";

const LoginLayout: React.FC = () => {
  // const onChange = (currentSlide: number) => {
  //   console.log(currentSlide);
  // };
  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <Header hideMenuLinks />
          <div className={s.bg}>
            <div className={s.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginLayout };
