import { Navigate, RouteObject } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import * as Company from "../../modules/Admin/pages/Company";
import { TrainingBase } from "../../pages/Admin/TrainingBase";
import EditUserProfile from "../../pages/Profile/EditUserProfile";
import { AppRoutesName } from "../AppRoutesName";

export const AdminRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/login",
        element: <Navigate to="/" replace />,
      },
      {
        path: "/",
        element: <Company.List />,
      },
      {
        path: "/company/create/:id",
        element: <Company.Create />,
      },
      {
        path: "/company/:id",
        element: <Company.Details />,
      },
      {
        path: "/company/:id/employees",
        element: <Company.EmployeesList />,
      },
      {
        path: "/company/:id/departments",
        element: <Company.DepartmentsList />,
      },
      {
        path: "/company/create",
        element: <Company.Create />,
      },
      {
        path: "/profile",
        element: <EditUserProfile />,
      },
      {
        path: "/appeals",
        element: <h1>Обращения</h1>,
      },
      {
        path: "/moderation",
        element: <h1>Модерация</h1>,
      },
      {
        path: "/feedback",
        element: <h1>Обратная связь</h1>,
      },
      {
        path: AppRoutesName.trainingBase,
        element: <TrainingBase />,
      },
    ],
  },
];
