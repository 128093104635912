// src/pages/Login/LoginPage.tsx
import { Alert, Form } from "antd";
import clsx from "clsx";
import React, { useState } from "react";
import { ApiService } from "../../api/services/ApiService";
import Button from "../../components/ui/Button";
import { TextField } from "../../components/ui/TextField";
import s from "./Login.module.scss";

const LoginPage: React.FC = () => {
  const [loginError, setLoginError] = useState("");
  const onFinish = async (values: any) => {
    try {
      const response = await ApiService.apiAccountsLoginCreate({
        email: values.username,
        password: values.password,
      });
      if (response) {
        localStorage.setItem("userInfo", JSON.stringify(response));
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      setLoginError("Ошибка входа. Проверьте данные.");
    }
  };

  return (
    <Form
      name="normal_login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      className={s.form}
    >
      <h2 className={s.title}>Авторизация</h2>
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите свое имя пользователя!",
          },
        ]}
        className={clsx(s.formGroup, s.marginBottom)}
      >
        <TextField placeholder="Введите e-mail" required fullWidth />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Пожалуйста, введите свой пароль!" }]}
        className={s.formGroup}
      >
        <TextField type="password" placeholder="Введите пароль" fullWidth required />
      </Form.Item>
      <Form.Item className={s.formGroup}>
        <a className={s.forgot} href="/forgot-password">
          Забыли пароль?
        </a>
      </Form.Item>
      <Form.Item className={clsx(s.formGroup, { [s.marginBottom]: loginError })}>
        <Button type="submit" className={s.submit} fullWidth>
          Войти в аккаунт
        </Button>
      </Form.Item>
      {loginError && <Alert type="error" message={loginError} />}
    </Form>
  );
};

export default LoginPage;
