import React, { FC } from "react";
import s from "./ProgressBar.module.scss";

type Props = {
  segments: {
    percent: number;
    color: string;
  }[];
};

const ProgressBar: FC<Props> = ({ segments }) => {
  return (
    <div className={s.progressBar}>
      {segments.map((segment, index) => (
        <div
          key={index}
          className={s.progressSegment}
          style={{
            width: `${segment.percent}%`,
            backgroundColor: segment.color,
          }}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
