import { SideBarItem } from "./sidebar.type";
import { ControlFilled, CopyFilled, ReadFilled, SignalFilled } from "@ant-design/icons";
import React from "react";

export const ManagerSidebarRoutes: SideBarItem[] = [
  {
    label: "Тренировки",
    key: "/",
    icon: <ReadFilled />,
    link: "/",
  },
  {
    label: "Отчеты",
    key: "/reports",
    icon: <CopyFilled />,
    link: "/reports",
  },
  {
    label: "РНП",
    key: "/rnp",
    icon: <ControlFilled />,
    link: "/rnp",
  },
  {
    label: "Аналитика",
    key: "/analytics",
    icon: <SignalFilled />,
    link: "/analytics",
  },
];
