export const Item2Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72" fill="none">
      <line x1="7.5" y1="72" x2="7.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="17.5" y1="72" x2="17.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="27.5" y1="72" x2="27.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="37.5" y1="72" x2="37.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="47.5" y1="72" x2="47.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <path
        d="M1 41.2812C10 41.2812 18.525 25.8534 27 26.5C40 27.4919 42.5 26.374 56 11V67C56 69.7614 53.7614 72 51 72H6C3.23857 72 1 69.7614 1 67V41.2812Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M1 41C8.5 41.5 17.9879 27.1677 26 26.5C38 25.5 39.5 31 55.5 11.5"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle cx="56" cy="11" r="1" fill="white" />
    </svg>
  );
};
