import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService, CompanyList } from "../../../../api";
import ArrowIcon from "../../../../icons/ArrowIcon";
import Status from "../../components/ui/Status/Status";
import s from "../../css/Company/CompanyList.module.scss";

export const List = () => {
  const [companies, setCompanies] = useState<CompanyList[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<CompanyList[]>([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loadCompanies = async (searchName?: string) => {
    const companies = await ApiService.apiAccountsCompanyList(searchName);
    setCompanies(companies);
    setFilteredCompanies(companies);
  };

  useEffect(() => {
    (async () => {
      loadCompanies();
    })();
  }, []);

  const handleSubmit = (values: any) => {
    loadCompanies(values.name);
  };

  const handleSearch = (values: any) => {
    handleSubmit(values);
  };

  const debouncedSearch = debounce(() => {
    const values = form.getFieldsValue();
    handleSearch(values);
  }, 500);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      debouncedSearch();
    }
  };

  const handleChange = (value: string) => {
    let filtered = [...companies];
    if (value === "active") {
      filtered = filtered.filter((c) => c.is_active === true);
    } else if (value === "inActive") {
      filtered = filtered.filter((c) => c.is_active === false);
    }
    setFilteredCompanies(filtered);
  };

  return (
    <div className={s.root}>
      <Form form={form} onFinish={handleSubmit}>
        <div className={s.search}>
          <Row justify="space-between" style={{ gap: "10px" }}>
            <Col>
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Form.Item noStyle name="name">
                  <Input
                    onKeyDown={handleKeyPress}
                    style={{
                      width: "350px",
                      borderRadius: "47px",
                      background: "#F6F6F6",
                      borderWidth: "0",
                      padding: "7px 7px 7px 20px",
                    }}
                    placeholder="Поиск"
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Select
                    onChange={handleChange}
                    defaultValue="all"
                    style={{ width: "111px", height: "35px" }}
                    options={[
                      { value: "all", label: "Все" },
                      { value: "active", label: "Активна" },
                      { value: "inActive", label: "Не активна" },
                    ]}
                  />
                </Form.Item>
                {/* <Button
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  style={{ marginLeft: "8px", padding: "10px" }}
                /> */}
              </div>
            </Col>
            <Col>
              <Button
                onClick={() => navigate("/company/create")}
                style={{
                  borderRadius: "47px",
                  border: "1px solid #444444",
                  height: "35px",
                }}
                icon={<PlusOutlined style={{ color: "#464646" }} />}
              >
                Добавить компанию
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <div className={s.wrap}>
        <div className={s.companies}>
          {filteredCompanies.map((company) => (
            <div className={s.company} key={company.id}>
              <div className={s.header}>
                <div className={s.subscription}>
                  Подписка с {dayjs(company.package_permission?.date_start).format("DD.MM.YYYY")} по{" "}
                  {dayjs(company.package_permission?.date_end).format("DD.MM.YYYY")}
                </div>
                <Status state={company.is_active} />
              </div>
              <div className={s.name}>{company.name}</div>
              <div className={s.label}>
                Владелец: <span>{company.owner}</span>
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <div className={s.label}>
                  Количество сотрудников: <span>{company.staff_count}</span>
                </div>
                <Button
                  type="link"
                  className={s.link}
                  onClick={() => navigate(`/company/${company?.id}`)}
                >
                  <ArrowIcon />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
