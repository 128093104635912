import {
  ContactsFilled,
  ControlFilled,
  CopyFilled,
  HomeFilled,
  SignalFilled,
} from "@ant-design/icons";
import { SideBarItem } from "./sidebar.type";

export const OwnerSidebarRoutes: SideBarItem[] = [
  {
    label: "Главная",
    key: "/",
    icon: <HomeFilled />,
    link: "/",
  },
  {
    label: "Отчеты",
    key: "/reports",
    icon: <CopyFilled />,
    link: "/reports",
  },
  {
    label: "РНП",
    key: "/rnp",
    icon: <ControlFilled />,
    link: "/rnp",
  },
  {
    label: "Сотрудники",
    key: "/employees",
    icon: <ContactsFilled />,
    link: "/employees",
  },

  {
    label: "Аналитика",
    key: "/analytics",
    icon: <SignalFilled />,
    link: "/analytics",
  },
];
