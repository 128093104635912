import { useEffect, useState } from "react";
import { ApiService, UserTraining } from "../../../api";
import {
  getCurrentCompanyId,
  getCurrentManagerSettingId,
  getCurrentUserId,
} from "../../../authHelper";
import InformationSection from "./sections/InformationSection/InformationSection";
import VideoListSection from "./sections/VideoListSection/VideoListSection";
import VideoSection from "./sections/VideoSection/VideoSection";
import s from "./TrainingBase.module.scss";

const TrainingBase = () => {
  const companyId = getCurrentCompanyId();
  const managerSettingId = getCurrentManagerSettingId();
  const userId = getCurrentUserId();
  const [trainings, setTrainings] = useState<UserTraining[]>([]);
  const [currentTraining, setCurrentTraining] = useState<UserTraining | undefined>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const response = await ApiService.apiMainUserTrainingListFilter(userId);
        setTrainings(response);
        setCurrentTraining(response[0]);
      } catch (error) {
        console.error("Error loading trainings", error);
      }
    })();
  }, [companyId, managerSettingId, userId]);

  const handleSelectTraining = (id: string) => {
    const selectedTraining = trainings.find((training) => training.id === id);
    if (selectedTraining) {
      setCurrentTraining(selectedTraining);
    }
  };

  const handleVideoEnd = async (id: string) => {
    try {
      const response = await ApiService.apiMainUserTrainingUpdate(id, {
        is_viewed: true,
        user: userId,
        training: currentTraining!.training,
      });

      if (response) {
        const updatedTrainings = trainings.map((training) =>
          training.id === id ? { ...training, is_viewed: true } : training,
        );
        setTrainings(updatedTrainings);
      }
    } catch (error) {
      console.error("Error updating training status:", error);
    }
  };

  return (
    <div className={s.wrapper}>
      {trainings.length ? (
        <>
          <div className={s.main}>
            <VideoSection
              videoUrl={currentTraining?.training_read.video_url}
              onVideoEnd={() => handleVideoEnd(currentTraining!.id)}
            />
            <InformationSection
              title={currentTraining?.training_read.title || "Тема не найдена"}
              description={currentTraining?.training_read.description || "Описание не найдено"}
              pdf={currentTraining?.training_read.pdf || null}
            />
          </div>
          <div className={s.sidebar}>
            <h4 className={s.title}>Вам доступны тренировки</h4>
            {trainings.map((item) => (
              <VideoListSection
                key={item.training_read.id}
                item={item}
                onSelect={handleSelectTraining}
              />
            ))}
          </div>
        </>
      ) : (
        <p>Тренингов нет</p>
      )}
    </div>
  );
};

export { TrainingBase };
