import { FC, useEffect, useRef, useState } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";

type VideoSectionProps = {
  videoUrl?: string;
  onVideoEnd: () => void;
};

const getVideoId = (url: string): string | null => {
  const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^\s&]+)/;
  const match = url?.match(regex);
  return match ? match[1] : null;
};

const VideoSection: FC<VideoSectionProps> = ({ videoUrl, onVideoEnd }) => {
  const videoId = videoUrl ? getVideoId(videoUrl) : null;
  const [lastPlayedTime, setLastPlayedTime] = useState(0); // Последняя зафиксированная позиция времени
  const playerRef = useRef<YouTubePlayer | null>(null);

  const opts = {
    height: "384",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 1,
      rel: 0,
      modestbranding: 1,
      disablekb: 0,
    },
  };

  const handlePlayerReady = (event: { target: YouTubePlayer }) => {
    playerRef.current = event.target;
    playerRef.current.seekTo(0);
  };

  const handleStateChange = (event: { target: YouTubePlayer; data: number }) => {
    const player = event.target;
    const currentTime = player.getCurrentTime();

    // Допустимый сдвиг вперед при нормальном воспроизведении (2 секунды)
    const allowedShift = 2;

    // Проверяем, что пользователь не перемотал видео вперёд на значительное расстояние
    if (currentTime > lastPlayedTime + allowedShift) {
      player.seekTo(lastPlayedTime); // Если перемотал значительно вперёд, возвращаем на последнюю просмотренную позицию
    }

    // Когда видео закончилось
    if (event.data === 0) {
      onVideoEnd();
    }
  };

  // Каждые несколько секунд проверяем текущее время видео и обновляем позицию
  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        setLastPlayedTime(currentTime); // Обновляем последнюю просмотренную точку
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Сбрасываем позицию воспроизведения при изменении URL видео
  useEffect(() => {
    setLastPlayedTime(0);
    if (playerRef.current) {
      playerRef.current.seekTo(0); // Перемещаем видео к началу
    }
  }, [videoUrl]);

  if (!videoId) {
    return <div>Видео недоступно</div>;
  }

  return (
    <div>
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={handlePlayerReady}
        onStateChange={handleStateChange}
        onEnd={onVideoEnd}
      />
    </div>
  );
};

export default VideoSection;
