const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM22 22.75C22.4142 22.75 22.75 22.4142 22.75 22V15.25C22.75 14.8358 22.4142 14.5 22 14.5C21.5858 14.5 21.25 14.8358 21.25 15.25V21.25H15.25C14.8358 21.25 14.5 21.5858 14.5 22C14.5 22.4142 14.8358 22.75 15.25 22.75H22ZM0.46967 1.53033L21.4697 22.5303L22.5303 21.4697L1.53033 0.46967L0.46967 1.53033Z"
        fill="#444444"
      />
    </svg>
  );
};
export default ArrowIcon;
