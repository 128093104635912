import { useTranslation } from "react-i18next";
import s from "./Hero.module.scss";

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s.images}>
        <img src="images/landingPage/hero.png" alt="" />
      </div>
      <div className={s.information}>
        <h1 className={s.title}>
          {t("Landing.banner.title")}
          <span>{t("Landing.banner.subtitle")}</span>
        </h1>
        <p className={s.description}>{t("Landing.banner.text")}</p>
        <div className={s.bottomContent}>
          <a className={s.details} href="#feedback" style={{ textDecoration: "none" }}>
            {t("Landing.banner.button")}
          </a>
          <a href="tel:+77777868987" target="_blank" className={s.phone} rel="noreferrer">
            +7 (777) 786 89 87
          </a>
        </div>
      </div>
    </div>
  );
};
