/* eslint-disable jsx-a11y/no-autofocus */
import clsx from "clsx";
import React, { HTMLInputTypeAttribute, InputHTMLAttributes, useRef, useState } from "react";
import s from "./styles.module.scss";

import InputMask from "react-input-mask";

export interface ITextField extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type?: HTMLInputTypeAttribute | "phone" | undefined;
  error?: string;
  success?: string;
  helperText?: string;
  fullWidth?: boolean;
  endIcon?: React.ReactNode;
}

export const TextField: React.FC<ITextField> = ({
  className,
  type,
  success,
  error,
  helperText,
  disabled,
  fullWidth,
  placeholder,
  endIcon,
  ...otherProps
}) => {
  const [innerValue, setInnerValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEndIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={clsx(s.inputRoot, { [s.fullWidth]: fullWidth })}>
      <div className={s.inputLabelGroup}>
        {type !== "phone" && (
          <input
            ref={inputRef}
            className={clsx(s.textField, className, {
              [s.error]: error,
              [s.success]: success,
              [s.disabled]: disabled,
            })}
            type={type || "text"}
            disabled={disabled}
            value={otherProps.value !== undefined ? otherProps.value : innerValue}
            onChange={(e) => {
              if (otherProps.onChange) otherProps.onChange(e);
              setInnerValue(e.target.value);
            }}
            autoFocus={otherProps.autoFocus}
            {...otherProps}
          />
        )}
        {type === "phone" && (
          <InputMask
            mask="+7 (999) 999 99 99"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (otherProps.onChange) otherProps.onChange(e);
              setInnerValue(e.target.value);
            }}
            value={otherProps.value || innerValue}
            disabled={disabled}
            inputMode="numeric"
            {...otherProps}
          >
            <input
              className={clsx(s.textField, className, {
                [s.error]: error,
                [s.success]: success,
                [s.disabled]: disabled,
              })}
              type="text"
              autoFocus={otherProps.autoFocus}
            />
          </InputMask>
        )}

        {endIcon && (
          <div
            onClick={handleEndIconClick}
            className={s.endIcon}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleEndIconClick();
              }
            }}
          >
            {endIcon}
          </div>
        )}

        <span
          className={clsx(s.label, {
            [s.small]: otherProps.value || innerValue,
            [s.disabled]: disabled,
          })}
        >
          {placeholder}
          {otherProps.required && <span style={{ color: "#F6392D" }}>{" *"}</span>}
        </span>
      </div>

      {error && <small className={clsx(s.error)}>{error}</small>}
      {success && <small className={s.success}>{success}</small>}
      {helperText && <small className={s.helper}>{helperText}</small>}
    </div>
  );
};
