// AddSectionModal.tsx
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Space } from "antd";
import React, { useEffect } from "react";

interface AddSectionModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  addSection: (sections: { field_id: string; column_name: string }[]) => void;
  handlePipelineChange: (value: string) => void;
  availableStatuses: any[]; // Список доступных статусов для выбора
  pipelines: any[];
}

export const AddSectionModal: React.FC<AddSectionModalProps> = ({
  visible,
  setVisible,
  addSection,
  handlePipelineChange,
  availableStatuses,
  pipelines,
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const sections = values.statuses.map((x: any) => ({
          field_id: x.id,
          column_name: x.name,
        }));
        addSection(sections);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // Очистка статусов при закрытии модалки
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <Modal
      visible={visible}
      title="Добавить секцию"
      okText="Добавить"
      cancelText="Отмена"
      onCancel={handleCancel}
      onOk={handleOk}
      destroyOnClose // Уничтожает модалку при закрытии, чтобы сбросить форму
    >
      <Form form={form} layout="vertical" name="add_section_form">
        <Form.Item
          name="pipeline"
          label="Воронка"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите воронку",
            },
          ]}
          style={{ minWidth: "280px", maxWidth: "354px" }}
        >
          <Select
            onChange={(value) => {
              handlePipelineChange(value);
              // Очистка статусов при выборе новой воронки
              form.setFieldsValue({ statuses: [] });
            }}
            placeholder="Выберите воронку"
          >
            {pipelines.map((x: any) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.List name="statuses">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Space
                  key={field.key}
                  align="baseline"
                  style={{ display: "flex", marginBottom: 8, alignItems: "flex-end" }}
                >
                  <Form.Item
                    label={`Статус №${index + 1}`}
                    required={false}
                    {...field}
                    name={[field.name, "id"]}
                    rules={[{ required: true, message: "Выберите статус" }]}
                  >
                    <Select placeholder="Выберите статус.." style={{ width: "200px" }}>
                      {availableStatuses.length > 0 ? (
                        availableStatuses.map((x: any) => (
                          <Select.Option key={x.id} value={x.id}>
                            {x.name}
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option value="" disabled>
                          Нет доступных статусов
                        </Select.Option>
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "name"]}
                    rules={[{ required: true, message: "Введите название секции" }]}
                  >
                    <Input placeholder="Название секции" />
                  </Form.Item>

                  {fields.length > 1 && (
                    <MinusCircleOutlined
                      onClick={() => remove(field.name)}
                      style={{ color: "red" }}
                    />
                  )}
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить секцию
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
