export const Item4Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72" fill="none">
      <line x1="7.5" y1="72" x2="7.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="17.5" y1="72" x2="17.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="27.5" y1="72" x2="27.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="37.5" y1="72" x2="37.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="47.5" y1="72" x2="47.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <path
        d="M1 51.3529C7.5 53.5 14.5 33 25.5 32.5C38.027 31.9306 41 12.5 56 12V67C56 69.7614 53.7614 72 51 72H6C3.23857 72 1 69.7614 1 67V51.3529Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M1 51.5C8.5 52 12 35 25.5 32.5C40.3798 29.7445 39.5 13 56 12"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle cx="56" cy="12" r="1" fill="white" />
    </svg>
  );
};
