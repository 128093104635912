import { Layout, Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, To, useLocation, useNavigate } from "react-router-dom";
import { RoleEnum } from "../../api";
import accountsApi from "../../api/Accounts/AccountsApi";
import { getCurrentUserGroups, getCurrentUserName, getUserRole } from "../../authHelper";
import { CustomStyledModal } from "../../components/ui/CustomStyledModal/CustomStyledModal";
import MenuIcon from "../../pages/LandingPage/sections/Header/icons/MenuIcon";
import { AdminSidebarRoutes } from "../../router/sidebar/AdminSidebarRoutes";
import { ManagerSidebarRoutes } from "../../router/sidebar/ManagerSidebarRoutes";
import { OwnerSidebarRoutes } from "../../router/sidebar/OwnerSidebarRoutes";
import { SideBarItem } from "../../router/sidebar/sidebar.type";
import s from "./MainLayout.module.scss";
import { Sidebar, Switch } from "./Sidebar";
import { MenuItems } from "./Sidebar/components/MenuItems";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void,
  danger?: boolean,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    danger,
  } as MenuItem;
}

const MainLayout: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState<string | null>(() => getUserRole());
  useEffect(() => {
    const fetchedRole = getUserRole();
    console.log("Fetched role:", fetchedRole);
    setRole(fetchedRole);
  }, []);
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const getCount = async () => {
    // const data = await ApiService.apiMainRequestsGetRequestsForApprovalList(
    //   userInfo.id,
    // );
    // setCount(data.length);
  };
  const handleMenuClick = (key: To) => {
    navigate(key);
    setMenuVisible(false); // Close the modal after navigation
  };
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;

  const checkUserToken = () => {
    if (!userInfo) {
      setIsLoggedIn(false);
      navigate("/login");
    }
    setIsLoggedIn(true);
    navigate("/");
  };

  useEffect(() => {
    checkUserToken(); // eslint-disable-next-line react-hooks/exhaustive-deps
    getCount();
  }, [isLoggedIn]);

  const getMenuItemsByRole = (role: string | null): SideBarItem[] => {
    switch (role) {
      case RoleEnum.OWNER:
        return OwnerSidebarRoutes;
      case RoleEnum.ROP:
        return OwnerSidebarRoutes;
      case RoleEnum.MANAGER:
        return ManagerSidebarRoutes;
      case RoleEnum.ADMINISTRATOR:
        return AdminSidebarRoutes;
      default:
        return [];
    }
  };
  const systemMenuItems = (): MenuItem[] => {
    const routes: SideBarItem[] = getMenuItemsByRole(role);
    return routes.map((route) =>
      getItem(route.label, route.key, route.icon, () => {
        navigate(route.link);
      }),
    );
  };
  // const { Sider } = Layout;
  const [items, setItems] = useState<MenuItem[]>([]);
  const [name, setName] = useState<string>("");
  const location = useLocation();
  const { i18n } = useTranslation();
  const privilegedMenuItems: MenuItem[] = [];

  useEffect(() => {
    const getGroups = () => getCurrentUserGroups();
    const userGroups = getGroups();
    setItems([...systemMenuItems(), ...(userGroups?.length > 0 ? privilegedMenuItems : [])]);
    const name = getCurrentUserName();
    setName(name);
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  const handleLogout = () => {
    accountsApi.logout();
    navigate("/login");
  };

  const [toggled, setToggled] = useState<boolean>(true);
  const handleToggleLanguage = () => {
    setToggled(!toggled);
    // const newLanguage = isToggled ? "ru" : "kz";
    // i18n.changeLanguage(newLanguage);
    // localStorage.setItem("language", newLanguage);
  };

  return (
    <Layout className={s.root}>
      <div className={s.sidebar}>
        <Sidebar userInfo={userInfo} />
      </div>

      <Layout className={s.content}>
        <div className={s.logo}>
          <div className={s.logo}>
            <NavLink to="/">
              <img src="/logo_satoo.svg" width={90} alt="Logo" />
            </NavLink>
          </div>

          <div className={s.logo}>
            <Switch
              onClick={handleToggleLanguage}
              toggled={toggled}
              label={toggled ? "ru" : "kz"}
            />
            <div
              onClick={toggleMenu}
              style={{ marginLeft: "10px" }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  toggleMenu();
                }
              }}
              aria-label="Edit item"
            >
              <MenuIcon />
            </div>
          </div>
        </div>

        {menuVisible && (
          <CustomStyledModal open={menuVisible} onCancel={toggleMenu} footer={null}>
            <div className={s.user}>
              <div className={s.profile}>
                <img
                  src={`https://ui-avatars.com/api/?name=${name}&color=FFD966&background=FFF1C6`}
                  alt="profile"
                />
              </div>
              <div className={s.info}>
                <div className={s.name}>{name}</div>
                <div className={s.email}>{userInfo?.email}</div>
              </div>
            </div>
            <Menu
              mode="inline"
              className={s.menu}
              items={items}
              selectedKeys={[location.pathname]}
              onClick={({ key }) => handleMenuClick(key)}
            />
            <MenuItems
              className={s.menu}
              style={{ marginTop: 0, paddingLeft: "8px" }}
              handleLogout={handleLogout}
            />
          </CustomStyledModal>
        )}

        {isLoggedIn ? <Outlet /> : null}
      </Layout>
    </Layout>
  );
};

export { MainLayout };
