/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Table } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService, CompanyRetrieve, DepartmentCompanyList } from "../../../../api";

export const DepartmentsList = () => {
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyRetrieve>();
  const [departments, setDepartments] = useState<DepartmentCompanyList[]>([]);
  const loadCompany = async (id: any) => {
    const company = await ApiService.apiAccountsCompanyRetrieve(id);
    setCompany(company);

    const departments = await ApiService.apiAccountsCompanyGetCompanyDepartmentsList(id);
    setDepartments(departments);
  };
  useEffect(() => {
    (async () => {
      loadCompany(id);
    })();
  }, []);

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <a href="/">Все компании</a>,
          },
          {
            title: <a href={`/company/${id}`}>{company?.name}</a>,
          },
          {
            title: <a href="#">Сотрудники</a>,
          },
        ]}
      />

      <Table bordered dataSource={departments} style={{ marginTop: "2.5em" }}>
        <Table.Column title="ID" dataIndex="id" />
        <Table.Column title="Название" dataIndex="name" />
        <Table.Column title="РОП" dataIndex="rop" />
      </Table>
    </>
  );
};
