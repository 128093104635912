import { FC } from "react";
import s from "./InformationSection.module.scss";

type Props = {
  title: string;
  description: string;
  pdf?: string | null;
  isWatched?: boolean;
};
const InformationSection: FC<Props> = ({ title, description, pdf, isWatched }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3 className={s.title}>{title}</h3>
        {isWatched ? <span className={s.watched}>Просмотрено</span> : null}
        {pdf && (
          <a href={pdf} download>
            <button className={s.downloadButton}>Скачать PDF</button>
          </a>
        )}
      </div>
      <p className={s.description}>{description}</p>
    </div>
  );
};
export default InformationSection;
