export const Item1Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72" fill="none">
      <line x1="7.5" y1="72" x2="7.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="17.5" y1="72" x2="17.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="27.5" y1="72" x2="27.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="37.5" y1="72" x2="37.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="47.5" y1="72" x2="47.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <path
        d="M1 33C10 33 15.5 4.00012 24 4.00012C37.5 4.00012 38 23.0001 56 23.0001V67C56 69.7615 53.7614 72 51 72H6C3.23857 72 1 69.7615 1 67V33Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M1 33C8.5 33.4999 15.9879 4.66766 24 3.99996C36 2.99992 39.5 24 56 23"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle cx="55" cy="23" r="1" fill="white" />
    </svg>
  );
};
