import React from "react";
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";
import { RoleEnum } from "../api";
import { getUserRole } from "../authHelper";
import { LoginLayout } from "../layouts/LoginLayout";
import { LandingPage } from "../pages/LandingPage";
import ForgotPasswordPage from "../pages/Login/ForgotPasswordPage";
import LoginPage from "../pages/Login/LoginPage";
import ResetPasswordPage from "../pages/Login/ResetPasswordPage";
import { AppRoutesName } from "./AppRoutesName";
import { AdminRoutes } from "./roles/admin";
import { ManagerRoutes } from "./roles/manager";
import { OwnerRoutes } from "./roles/owner";

const AppRoutesComponent: React.FC = () => {
  const role = getUserRole();

  const userRoutes = (): RouteObject[] => {
    if (!role) {
      return [
        {
          element: <LoginLayout />,
          children: [
            {
              path: "/",
              element: <Navigate to="/login" replace />,
            },
            {
              path: "/forgot-password",
              element: <ForgotPasswordPage />,
            },
            {
              path: "/login",
              element: <LoginPage />,
            },
            {
              path: "/reset-password/:token",
              element: <ResetPasswordPage />,
            },
          ],
        },
      ];
    }

    switch (role) {
      case RoleEnum.ADMINISTRATOR:
        return AdminRoutes;
      case RoleEnum.MANAGER:
        return ManagerRoutes;
      case RoleEnum.OWNER:
      case RoleEnum.ROP:
        return OwnerRoutes;
      default:
        return [];
    }
  };

  const routes: RouteObject[] = [
    {
      path: AppRoutesName.landingPage,
      element: <LandingPage />,
    },
    ...userRoutes(),
  ];

  console.log("Router created with routes:", routes);

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default AppRoutesComponent;
