import React from "react";
import s from "./Switch.module.scss";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  toggled?: boolean;
  onClick?: () => void;
};

const Switch: React.FC<Props> = ({ label, toggled, ...otherProps }) => {
  const uniqueId = otherProps.id || `customSwitch-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div className={s.switch}>
      <input
        onClick={otherProps.onClick}
        checked={toggled}
        type="checkbox"
        id={uniqueId}
        {...otherProps}
      />
      <label htmlFor={uniqueId}>{label}</label>
    </div>
  );
};

export { Switch };
