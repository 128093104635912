import { FC } from "react";
import clsx from "clsx";
import s from "./Status.module.scss";

type Props = {
  state?: boolean;
};
const Status: FC<Props> = ({ state }) => {
  return (
    <div
      className={clsx(s.status, {
        [s.statusActive]: state,
        [s.statusInactive]: !state,
      })}
    >
      {state ? "активна" : "неактивна"}
    </div>
  );
};
export default Status;
