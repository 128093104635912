/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */

/**
 * * `Bitrix` - Bitrix
 * * `AmoCRM` - Amo CRM
 */
export enum TypeEnum {
  BITRIX = "Bitrix",
  AMO_CRM = "AmoCRM",
}
