/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
import { FC, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import s from "./NeedResults.module.scss"; // Импортируем стили, если необходимо

type VideoSectionProps = {
  videoUrl?: string;
  active: boolean;
  index: number;
  onSelect: (index: number) => void;
};

const VideoSection: FC<VideoSectionProps> = ({ videoUrl, active, index, onSelect }) => {
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (playerRef.current) {
      if (active) {
        playerRef.current.getInternalPlayer()?.playVideo?.();
        playerRef.current.getInternalPlayer()?.play?.();
      } else {
        playerRef.current.getInternalPlayer()?.pauseVideo?.();
        playerRef.current.getInternalPlayer()?.pause?.();
      }
    }
  }, [active]);

  if (!videoUrl) {
    return <div className={s.videoUnavailable}>Видео недоступно</div>;
  }

  return (
    <div className={`${s.videoItem} ${active ? s.active : ""}`} onClick={() => onSelect(index)}>
      <ReactPlayer
        className={s.reactPlayer}
        ref={playerRef}
        url={videoUrl}
        width="100%"
        height="730px"
        controls
        playing={active}
        config={{
          youtube: {
            playerVars: {
              rel: 0,
              modestbranding: 1,
              disablekb: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default VideoSection;
