import { useTranslation } from "react-i18next";
import s from "./AboutUs.module.scss";

export const AboutUs = () => {
  const { t, i18n } = useTranslation();

  const benefits = [
    {
      icon: "images/landingPage/clients.png",
      title: t("Landing.aboutus.firstbenefit"),
      subtitle: t("Landing.aboutus.firsttext"),
    },
    {
      icon: "/images/landingPage/tools.png",
      title: t("Landing.aboutus.secondbenefit"),
      subtitle: t("Landing.aboutus.secondtext"),
    },
    {
      icon: "/images/landingPage/departments.png",
      title: t("Landing.aboutus.thirdbenefit"),
      subtitle: t("Landing.aboutus.thirdtext"),
    },
    {
      icon: "/images/landingPage/cases.png",
      title: t("Landing.aboutus.fourthbenefit"),
      subtitle: t("Landing.aboutus.fourthtext"),
    },
  ];
  return (
    <div className={s.wrapper} id="aboutUs">
      <div className={s.header}>
        <span className={s.dot} />
        <span className={s.title}>{t("Landing.header.aboutus").toLowerCase()}</span>
      </div>
      <div className={s.main}>
        <div>
          {i18n.language === "ru" && (
            <h3 className={s.title}>
              Узнайте о нашей команде и преимуществах, которые сделают ваш бизнес{" "}
              <span>более успешным</span>
            </h3>
          )}
          {i18n.language === "kz" && (
            <h3 className={s.title}>
              Бизнесіңізді <span>табысты</span> ететін артықшылықтар мен командамыз туралы біліңіз
            </h3>
          )}
          <div className={s.benefits}>
            {benefits.map((benefit, index) => (
              <div className={s.benefit} key={index}>
                <div className={s.benefitIcon}>
                  <img src={benefit.icon} alt="icon" />
                </div>
                <div>
                  <p className={s.benefitTitle}>{benefit.title}</p>
                  <p className={s.benefitSubtitle}>{benefit.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={s.team} />
      </div>
    </div>
  );
};
