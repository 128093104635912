import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { ApiService, DepartmentCompanyList, ManagerSettingsCompanyList } from "../../../api";

type UpdateUserModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  selectedUser: any;
  manager_settings?: ManagerSettingsCompanyList[];
  departments?: DepartmentCompanyList[];
  fetch: number;
  setFetch: (fetch: number) => void;
};

export const UpdateUserModal = ({
  visible,
  setVisible,
  selectedUser,
  manager_settings,
  departments,
  fetch,
  setFetch,
}: UpdateUserModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue({
        full_name: selectedUser.full_name,
        email: selectedUser.email,
        dob: selectedUser.dob && dayjs(selectedUser.dob),
        manager_setting: selectedUser.manager_settings,
        department: selectedUser.department,
      });
    }
  }, [selectedUser]);

  const handleSubmit = async (values: any) => {
    try {
      await ApiService.apiAccountsUserUpdateUserCreate({
        full_name: values.full_name,
        dob: dayjs(values.dob.$d).format("YYYY-MM-DD"),
        email: values.email,
        user_id: selectedUser.id,
        department: values.department,
        manager_settings: values.manager_setting,
      });
      message.success("Данные сотрудника успешно обновлены");
      setFetch(fetch + 1);
      setVisible(false);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <Modal
      title="Редактировать данные сотрудника"
      onCancel={() => setVisible(false)}
      open={visible}
      footer={
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Button
              onClick={() => {
                console.log("click");
              }}
              style={{
                width: "100%",
                height: "40px",
                fontSize: "16px",
              }}
            >
              Сбросить пароль
            </Button>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Button
              type="primary"
              onClick={() => form.submit()}
              style={{
                width: "100%",
                height: "40px",
                fontSize: "16px",
              }}
            >
              Подтвердить
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={form} style={{ marginTop: "1em" }} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="full_name" label="Фамилия и имя">
          <Input />
        </Form.Item>
        <Form.Item name="dob" label="Дата рождения">
          <DatePicker style={{ width: "100%" }} placeholder="Выберите дату" />
        </Form.Item>
        <Form.Item name="email" label="e-mail">
          <Input />
        </Form.Item>
        <Form.Item name="department" label="Подразделение">
          <Select>
            {departments?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="manager_setting" label="Настройки менеджера">
          <Select>
            {manager_settings?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
