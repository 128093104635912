import { Form, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "../../components/ui/TextField";
import s from "./Login.module.scss";
import Button from "../../components/ui/Button";
import { ApiService } from "../../api";

const ForgotPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    try {
      ApiService.apiPasswordResetCreate({ email: values.email });
      message.success("Письмо для сброса пароля было успешно отправлено");
      navigate("/login");
    } catch (e) {
      message.error("Что-то пошло не так.");
    }
  };

  return (
    <>
      <h2 className={s.title}>Восстановить пароль</h2>
      <Form form={form} onFinish={handleSubmit} className={s.form}>
        <Form.Item name="email" className={s.formGroup}>
          <TextField placeholder="Введите e-mail" required fullWidth />
        </Form.Item>
        <Form.Item className={s.formGroup}>
          <p className={s.small}>На Вашу почту были повторно отправлены данные для входа</p>
        </Form.Item>
        <Form.Item className={s.formGroup}>
          <Button type="submit" className={s.submit} fullWidth>
            Отправить мне письмо
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotPasswordPage;
