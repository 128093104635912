/* eslint-disable no-confusing-arrow */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaThumbsUp } from "react-icons/fa"; // Импорт иконки "Большой палец вверх"
import InputMask from "react-input-mask";
import * as yup from "yup";
import s from "./Feedback.module.scss";

interface FormData {
  name: string;
  phone: string;
  comment: string;
}

interface ServerResponse {
  result: string;
  message?: string;
}

export const Feedback: React.FC = () => {
  const { t, i18n } = useTranslation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t("Landing.feedback.name") as string)
      .max(20, t("Landing.feedback_name_max_length") as string)
      .min(2, t("Landing.feedback_name_min_length") as string),
    comment: yup
      .string()
      .required(t("Landing.feedback.comment") as string)
      .max(300, t("Landing.feedback_comment_max_length") as string)
      .min(5, t("Landing.feedback_comment_min_length") as string),
    phone: yup
      .string()
      .required(t("Landing.feedback.phone") as string)
      .test("length", t("Landing.feedback.phone") as string, (value) => {
        if (value) {
          const numericValue = value.replace(/\D/g, "");
          return numericValue.length === 11;
        }
        return false;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      phone: "",
      comment: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  const GOOGLE_SCRIPT_URL =
    "https://script.google.com/macros/s/AKfycbwF33gLAzuE7RKDyAQw4EB-eI0EzO6E3r7u3whSH7FVTAFzch7UMJMy22rbPBvbKD9uFA/exec";

  const handleFeedbackClick = async (data: FormData) => {
    setIsSubmitting(true);
    setStatus(null);

    try {
      const formattedPhone = `'${data.phone}`;

      const formBody = new URLSearchParams();
      formBody.append("name", data.name);
      formBody.append("phone", formattedPhone);
      formBody.append("comment", data.comment);

      const response = await fetch(GOOGLE_SCRIPT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody.toString(),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result: ServerResponse = await response.json();

      if (result.result === "success") {
        setStatus("success");
        setValue("phone", "");
        reset();
      } else {
        setStatus("error");
        console.error("Ошибка сервера:", result.message);
      }
    } catch (error) {
      console.error("Ошибка отправки данных:", error);
      setStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let timer: number;
    if (status === "success") {
      timer = window.setTimeout(() => {
        setStatus(null);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [status]);

  return (
    <div className={s.wrapper} id="feedback">
      {i18n.language === "ru" && (
        <h3 className={s.title}>
          Получите консультацию от наших специалистов
          <span>о сотрудничестве и регистрации на платформе Satoo</span>
        </h3>
      )}
      {i18n.language === "kz" && (
        <h3 className={s.title}>
          Біздің мамандардан
          <span>Satoo платформасында серіктестік және тіркелу туралы</span>
          кеңес алыңыз
        </h3>
      )}
      <form onSubmit={handleSubmit(handleFeedbackClick)} className={s.form}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field }) => (
              <input
                placeholder={t("Landing.feedback.name") as string}
                type="text"
                {...field}
                className={classNames({ [s.inputError]: errors.name })}
              />
            )}
          />
          <p className={s.errorP}>{errors?.name?.message}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputMask
            mask="+7 (999)-999-99-99"
            defaultValue=""
            alwaysShowMask={false}
            type="text"
            placeholder={t("Landing.feedback.phone")}
            className={classNames({ [s.inputError]: errors.phone })}
            {...register("phone", { required: true })}
          />
          <p className={s.errorP}>{errors?.phone?.message}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Controller
            control={control}
            name="comment"
            defaultValue=""
            render={({ field }) => (
              <textarea
                placeholder={t("Landing.feedback.comment") as string}
                {...field}
                className={classNames({ [s.inputError]: errors.comment })}
                rows={3}
              />
            )}
          />
          <p className={s.errorP}>{errors?.comment?.message}</p>
        </div>
        <button type="submit" disabled={isSubmitting} className={s.submitButton}>
          <div className={s.buttonContent}>
            {isSubmitting && <div className={s.spinner} aria-label="Загрузка" />}{" "}
            {status === "success" && <FaThumbsUp className={s.thumbsUp} aria-label="Успешно" />}{" "}
            {!isSubmitting && status !== "success" && (
              <span className={s.buttonText}>{t("Landing.feedback.button")}</span>
            )}
          </div>
        </button>
      </form>
    </div>
  );
};
