import { RightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./NeedResults.module.scss";
import VideoSection from "./VideoSection";

const NeedResults: React.FC = () => {
  const { t, i18n } = useTranslation();
  const steps = [
    t("Landing.results.firststep"),
    t("Landing.results.secondstep"),
    t("Landing.results.thirdstep"),
    t("Landing.results.fourthstep"),
    t("Landing.results.fifthstep"),
  ];

  const videoUrls: string[] = [
    "https://www.youtube.com/watch?v=caDggo97IO0",
    "https://www.youtube.com/watch?v=gku-xz7aZno",
    "https://www.youtube.com/watch?v=45dUGF8aZC4",
    "https://www.youtube.com/watch?v=2rArPwjsCrI",
    "https://www.youtube.com/watch?v=FMDN-Qayea0",
    "https://www.youtube.com/watch?v=7UGOn9maDgc",
    "https://www.youtube.com/watch?v=7EAQcsfd5oY",
    "https://www.youtube.com/watch?v=A2wp6xD5Xk8",
    "https://www.youtube.com/shorts/EamqPvlxtpQ",
  ];

  const carouselRef = useRef<any>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handleAfterChange = (current: number) => {
    setCurrentSlide(current);
  };

  const handleSelectVideo = (index: number) => {
    setCurrentSlide(index);
    if (carouselRef.current) {
      carouselRef.current.goTo(index);
    }
  };

  return (
    <div className={s.wrapper} id="results">
      <div className={s.main}>
        <div className={s.heading}>
          <p className={s.subtitle}>
            <span />
            {t("Landing.results.point")}
          </p>
          {i18n.language === "ru" && (
            <h3 className={s.title}>
              Хотите также <span> улучшить</span> <br />
              свой отдел продаж?
            </h3>
          )}
          {i18n.language === "kz" && (
            <h3 className={s.title}>
              Саті бөліміңізді <span> жақсартқыңыз</span> <br />
              келеді ме?
            </h3>
          )}
          <p className={s.description}>{t("Landing.results.subtitle")}</p>
        </div>
        {steps.map((step, index) => (
          <div className={s.step} key={index}>
            <p className={s.index}>0{index + 1}</p>
            <p className={s.label}>{step}</p>
          </div>
        ))}
      </div>
      <div className={s.reviews} style={{ position: "relative" }}>
        <Carousel ref={carouselRef} dots infinite={false} afterChange={handleAfterChange}>
          {videoUrls.map((url, index) => (
            <div key={index}>
              <VideoSection
                key={index}
                index={index}
                videoUrl={url}
                onSelect={handleSelectVideo}
                active={index === currentSlide}
              />
            </div>
          ))}
        </Carousel>
        <button onClick={handleNext} className={s.nextButton}>
          <RightOutlined style={{ fontSize: "16px", color: "#000" }} />
        </button>
      </div>
    </div>
  );
};

export default NeedResults;
