import { Steps, message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService, CompanyRetrieve } from "../../../../api";
import { CreateCompanyFifthStep } from "../../components/Company/CreateCompanyFifthStep";
import { CreateCompanyFirstStep } from "../../components/Company/CreateCompanyFirstStep";
import { CreateCompanyFourthStep } from "../../components/Company/CreateCompanyFourthStep";
import { CreateCompanySecondStep } from "../../components/Company/CreateCompanySecondStep";
import { CreateCompanySeventhStep } from "../../components/Company/CreateCompanySeventhStep";
import { CreateCompanySixthStep } from "../../components/Company/CreateCompanySixthStep";
import { NewCreateCompanyThirdStep } from "../../components/Company/NewCreateCompanyThirdStep";
import "../../css/Company/AntdCustomStyle.css";
import s from "../../css/Company/CompanyCreate.module.scss";

const { Step } = Steps;

export const Create = () => {
  const [current, setCurrent] = useState<number>(0);
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyRetrieve>();
  const [fetch, setFetch] = useState<number>(0);
  const loadCompany = async (id: string) => {
    try {
      const result = await ApiService.apiAccountsCompanyRetrieve(id);
      setCompany(result);
      setCurrent(result.step_register!);
    } catch (e) {
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    (async () => {
      if (id) {
        await loadCompany(id!);
      }
    })();
  }, [fetch]);

  const handleStepBack = async () => {
    try {
      await ApiService.apiAccountsCompanyStepBackUpdate({ company_id: id! });
      setFetch(fetch + 1);
    } catch {
      message.error("Something went wrong");
    }
  };

  const renderForm = () => {
    switch (current) {
      case 0:
        return (
          <CreateCompanyFirstStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      case 1:
        return (
          <CreateCompanySecondStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      case 2:
        return (
          <NewCreateCompanyThirdStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      case 3:
        return (
          <CreateCompanyFourthStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      case 4:
        return (
          <CreateCompanyFifthStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      case 5:
        return (
          <CreateCompanySixthStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      case 6:
        return (
          <CreateCompanySeventhStep
            company_id={id}
            fetch={fetch}
            setFetch={setFetch}
            company={company}
            current={current}
            setCurrent={setCurrent}
            stepBack={handleStepBack}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <h2 className={s.title}>Регистрация новой компании</h2>
      <Steps
        size="small"
        current={current}
        labelPlacement="vertical"
        style={{ overflowX: "scroll" }}
      >
        <Step title="Название компании" onClick={() => setCurrent(0)} />
        <Step title="Пакет разрешений" onClick={() => setCurrent(1)} />
        <Step title="Настройки менеджеров" onClick={() => setCurrent(2)} />
        <Step title="Владелец компании" onClick={() => setCurrent(3)} />
        <Step title="РОПы и подразделения" onClick={() => setCurrent(4)} />
        <Step title="Сотрудники" onClick={() => setCurrent(5)} />
        <Step title="РНП" onClick={() => setCurrent(6)} />
      </Steps>
      {renderForm()}
    </>
  );
};
