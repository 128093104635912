import { Button, Form, Input, Select, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService, TypeEnum } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

export const CreateCompanyFirstStep = ({ company, setCurrent, current }: CreateCompanyProps) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      name: company?.name,
      crm_type: company?.crm_type,
      webhook_or_token: company?.webhook_or_token,
    });
  }, [company]);

  const handleTestConnection = async () => {
    try {
      await ApiService.apiAccountsCompanyTestCrmConnectionCreate({
        type: form.getFieldValue("crm_type"),
        webhook_or_token: form.getFieldValue("webhook_or_token"),
      });
      message.success("Connection tested successfully");
      setDisabled(false);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      const result = await ApiService.apiAccountsCompanyCreate({
        webhook_or_token: values.webhook_or_token,
        name: values.name,
        type: values.crm_type,
        company_id: company?.id,
      });
      navigate(`/company/create/${result.company_id}`);
      setCurrent(current + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 1. Название компании и подключение к CRM</h4>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="1. Напишите имя компании" name="name">
          <Input style={{ maxWidth: "354px" }} className={s["step-input"]} />
        </Form.Item>
        <div className={s.flex}>
          <Form.Item label="2. Выберите тип CRM" name="crm_type">
            <Select className={s.select}>
              <Select.Option value={TypeEnum.BITRIX}>Bitrix</Select.Option>
              <Select.Option value={TypeEnum.AMO_CRM}>Amo CRM</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => prevValues.crm_type !== curValues.crm_type}
          >
            {({ getFieldValue }) => {
              const crmType = getFieldValue("crm_type") || undefined;
              if (crmType === TypeEnum.BITRIX) {
                return (
                  <div>
                    <Space>
                      <Form.Item label="Вебхук" name="webhook_or_token">
                        <Input className={s["step-input"]} />
                      </Form.Item>
                      <Form.Item label=" ">
                        <Button type="primary" onClick={() => handleTestConnection()}>
                          Проверить
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                );
              }
              if (crmType === TypeEnum.AMO_CRM) {
                return (
                  <Space>
                    <Form.Item label="Токен" name="webhook_or_token">
                      <Input
                        className={s["step-input"]}
                        style={{ maxWidth: "372px", minWidth: "280px", position: "relative" }}
                      />
                    </Form.Item>
                    <Form.Item label=" ">
                      <Button
                        style={{
                          borderRadius: "47px",
                          background: "#FFD966",
                          boxShadow: "none",
                          position: "absolute",
                          right: "22px",
                          bottom: "-2px",
                          height: "35px",
                        }}
                        type="primary"
                        onClick={() => handleTestConnection()}
                      >
                        Проверить
                      </Button>
                    </Form.Item>
                  </Space>
                );
              }
              return null;
            }}
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            style={{ borderRadius: "47px" }}
          >
            Далее
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
