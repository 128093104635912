export const Item3Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72" fill="none">
      <line x1="7.5" y1="72" x2="7.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="17.5" y1="72" x2="17.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="27.5" y1="72" x2="27.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="37.5" y1="72" x2="37.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <line x1="47.5" y1="72" x2="47.5" y2="2.2151e-08" stroke="white" strokeOpacity="0.25" />
      <path
        d="M1 17.5C9 19.5 19 46.5 27 48.5C36.5 50.875 34.5 36.5 56 35V67C56 69.7615 53.7614 72 51 72H6C3.23857 72 1 69.7615 1 67V17.5Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M1 17.5C8.5 18 19.974 48.0279 28 48.5C36.5 49 35 36.5 56 35"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle cx="56" cy="35" r="1" fill="white" />
    </svg>
  );
};
