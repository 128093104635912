/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Input, message, Modal, Select, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC, useEffect, useState } from "react";
import { ApiService, CompanyList, Training } from "../../../../../../api";
import Button from "../../../../../../components/ui/Button";
import s from "./AddVideoModal.module.scss";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  trainingBaseData: Training[];
  fetchTrainingBase: () => void;
};

type FormDataType = {
  link: string;
  title: string;
  block: string | null;
  module: string | null;
  company_id: string | null;
  manager_setting_id: string | null;
  description: string;
  pdf: any;
};

const initialData: FormDataType = {
  link: "",
  title: "",
  block: null,
  module: null,
  description: "",
  pdf: null,
  company_id: null,
  manager_setting_id: null,
};

const AddVideoModal: FC<Props> = ({ isOpen, handleClose, trainingBaseData, fetchTrainingBase }) => {
  const [, setSelectOptions] = useState<{ label: string; value: string }[]>([]);
  const [inputData] = useState<FormDataType>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const options = trainingBaseData.map((block) => ({
      label: block.title,
      value: block.id,
    }));
    setSelectOptions(options);
  }, [trainingBaseData]);
  const [companies, setCompanies] = useState<CompanyList[]>([]);
  const [managerSettings, setManagerSettings] = useState<any[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [form] = Form.useForm();

  // const handleChangePdf = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     onChangeFormData("pdf", e.target.files[0]);
  //   }
  // };
  // const onChangeFormData = (key: keyof FormDataType, value: any) => {
  //   setInputData((prev) => ({
  //     ...prev,
  //     [key]: value,
  //   }));
  // };
  const loadCompanies = async () => {
    const companies = await ApiService.apiAccountsCompanyList();

    setCompanies(companies);
  };

  useEffect(() => {
    (async () => {
      await loadCompanies();
    })();
  }, []);

  const [companyId, setCompanyId] = useState("");

  const loadBlocks = async (value: string) => {
    const blocks = (await ApiService.apiMainRegisterBlockListFilter(companyId, value)) as any;
    setBlocks(blocks);
  };

  const loadManagerSettings = async (value: any) => {
    setCompanyId(value);
    const company = await ApiService.apiAccountsCompanyRetrieve(value);
    setManagerSettings(company.company_manager_settings);
  };

  const loadModules = async (blockId: number) => {
    console.log(blockId);
    try {
      const response = await ApiService.apiMainRegisterCriteriaListFilter(blockId);
      if (response) {
        setModules(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmitForm = async (values: any) => {
    try {
      setLoading(true);

      await ApiService.apiMainTrainingCreate({
        title: values.title,
        description: values.description,
        block: values.block,
        module: values.module,
        manager_settings: values.manager_settings,
        video_url: values.link,
        company: values.company,
        pdf: values.pdf[0].originFileObj,
      });
      message.success("Вы успешно загрузили видео");

      fetchTrainingBase();

      handleClose();
    } catch (error) {
      message.error("Ошибка при отправке данных");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} title="Добавить видео" onCancel={handleClose} footer={null}>
      <Form className={s.form} onFinish={onSubmitForm} form={form}>
        <Form.Item noStyle name="link">
          <Input placeholder="https://" size="large" value={inputData.link} required />
        </Form.Item>
        <Form.Item noStyle name="company">
          <Select
            placeholder="Выберите компанию"
            size="large"
            value={inputData.company_id}
            onChange={(value) => {
              loadManagerSettings(value);
            }}
          >
            {companies.map((x: CompanyList) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle name="manager_settings">
          <Select
            placeholder="Выберите тип менеджера"
            size="large"
            value={inputData.manager_setting_id}
            onChange={(value) => {
              loadBlocks(value);
            }}
          >
            {" "}
            {managerSettings.map((x: any) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle name="block">
          <Select
            placeholder="Выберите блок"
            size="large"
            value={inputData.block}
            onChange={(value) => {
              const block = blocks.find((x: any) => Number(x.id) === Number(value));
              loadModules(block.id);
            }}
          >
            {blocks.map((x: any) => (
              <Select.Option key={x.id} value={x.block}>
                {x.block}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle name="module">
          <Select placeholder="Выберите модуль" size="large" value={inputData.module}>
            {modules.map((x: any) => (
              <Select.Option key={x.id} value={x.module}>
                {x.module}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle name="title">
          <Input placeholder="Заголовок" size="large" value={inputData.title} required />
        </Form.Item>
        <Form.Item noStyle name="description">
          <TextArea className={s.description} placeholder="Описание" />
        </Form.Item>
        <Form.Item
          name="pdf"
          noStyle
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите pdf",
            },
          ]}
        >
          <Upload
            name="nda"
            listType="text"
            beforeUpload={() => {
              return false;
            }}
            multiple={false}
          >
            <a href="#">Загрузить</a>
          </Upload>
        </Form.Item>
        <Button type="submit" loading={loading} onClick={() => form.submit()}>
          Добавить
        </Button>
      </Form>
    </Modal>
  );
};
export { AddVideoModal };
