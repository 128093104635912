import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService, DecompositionRequest } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

export const CreateCompanySeventhStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  setCurrent,
  current,
  stepBack,
}: CreateCompanyProps) => {
  const [form] = Form.useForm();
  const [pipelines, setPipelines] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [isDecompositionExists, setIsDecompositionExists] = useState(false);
  const navigate = useNavigate();

  const loadPipelines = async () => {
    const result = await ApiService.apiMainCrmPipelineRetrieve(company_id!);
    setPipelines(result);
  };

  const checkDecompositionExists = async () => {
    try {
      const decomposition = await ApiService.apiMainRnpGetDecompositionsByCompanyCreate(
        company_id!,
      );
      if (decomposition.length > 0) {
        setIsDecompositionExists(true);
      } else {
        setIsDecompositionExists(false);
      }
    } catch (error) {
      message.error("Ошибка при проверке наличия декомпозиции.");
    }
  };

  useEffect(() => {
    loadPipelines();
    checkDecompositionExists();
  }, [fetch, company_id]);

  const handlePipelineChange = async (value: any) => {
    const statuses = await ApiService.apiMainPipelineStatusList(company_id!, value);
    setStatuses(statuses);
  };

  const handleNextStep = async () => {
    if (company && company_id !== undefined) {
      try {
        const newStepRegister = (company.step_register ?? 0) + 1;

        await ApiService.apiAccountsCompanyPartialUpdate(company_id, {
          step_register: newStepRegister,
          is_active: true,
        });

        setFetch(fetch + 1);
        setCurrent(current + 1);
        message.success("RNP has been added successfully. Company registration has been finished.");
        navigate("/");
      } catch (e) {
        message.error("Something went wrong");
      }
    } else {
      message.error("Company or company ID is not defined.");
    }
  };

  const handleAddRnp = async (values: any) => {
    try {
      const data = {
        pipeline_id: values.pipeline,
        company: company_id,
        department: values.department,
        lead_field_id: values.lead_status,
        columns: values.statuses.map((x: any, index: number) => ({
          position: index,
          field_id: x.id,
          column_name: x.name,
        })),
        revenue_field_id: values.revenue_field_id,
        sales_field_id: values.sales_field_id,
      };

      await ApiService.apiMainRnpCreate(data as DecompositionRequest);
      message.success("Данные успешно добавлены!");
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Что-то пошло не так при добавлении данных");
    }
  };

  return (
    <div>
      <h4 className={s.stepTitle}>Шаг 7. Заполните настройки для таблицы РНП</h4>
      <Form form={form} layout="horizontal" onFinish={handleAddRnp}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="department"
              label="Департамент"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, напишите название",
                },
              ]}
              style={{ minWidth: "280px", maxWidth: "354px" }}
            >
              <Select>
                {company?.company_departments.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="pipeline"
              label="Воронка"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите воронку",
                },
              ]}
              style={{ minWidth: "280px", maxWidth: "354px" }}
            >
              <Select onChange={handlePipelineChange}>
                {pipelines.map((x: any) => (
                  <Select.Option value={x.id}>{x.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.List name="statuses">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item label={`Статус №${index + 1}`} required={false} key={field.key}>
                      <Space key={field.key} style={{ marginBottom: 8 }} align="baseline">
                        <Form.Item {...field} noStyle name={[field.name, "id"]}>
                          <Select placeholder="Выберите статус.." style={{ width: "100%" }}>
                            {statuses.map((x: any) => (
                              <Select.Option value={x.id}>{x.name}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item {...field} noStyle name={[field.name, "name"]}>
                          <Input placeholder="Название статуса" />
                        </Form.Item>
                      </Space>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          style={{ marginLeft: 10 }}
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{
                        minWidth: "280px",
                        maxWidth: "354px",
                        borderRadius: "47px",
                        background: "#F3F5FC",
                        border: "none",
                        height: "35px",
                      }}
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      disabled={isDecompositionExists} // Деактивация кнопки, если декомпозиция уже существует
                    >
                      Добавить статус
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item style={{ marginTop: "1em" }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                disabled={isDecompositionExists} // Деактивация кнопки, если декомпозиция уже существует
              >
                Добавить
              </Button>
            </Form.Item>

            <Form.Item style={{ marginTop: "1em" }}>
              <Button
                style={{ marginRight: "1em", borderRadius: "57px" }}
                onClick={() => stepBack()}
              >
                Назад
              </Button>

              <Button
                type="primary"
                onClick={() => handleNextStep()}
                style={{ borderRadius: "57px" }}
                disabled={isDecompositionExists} // Деактивация кнопки, если декомпозиция уже существует
              >
                Далее
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
