import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { ApiService, RoleEnum } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

export const CreateCompanyFourthStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  stepBack,
}: CreateCompanyProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      full_name: company?.owner_name !== "N/A" ? company?.owner_name : null,
      email: company?.owner_email !== "N/A" ? company?.owner_email : null,
      phone: company?.owner_phone !== "N/A" ? company?.owner_phone : null,
    });
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      await ApiService.apiAccountsCompanyAddOwnerCreate({
        full_name: values.full_name,
        email: values.email,
        phone: values.phone,
        role: RoleEnum.OWNER,
        company_id: company_id!,
      });
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 4. Введите данные владельца компании</h4>
      <Form form={form} onFinish={handleSubmit} style={{ minWidth: "280px", maxWidth: "354px" }}>
        <Form.Item name="full_name">
          <Input placeholder="ФИО владельца" className={s["step-input"]} />
        </Form.Item>
        <Form.Item name="email">
          <Input placeholder="e-mail" className={s["step-input"]} />
        </Form.Item>
        <Form.Item name="phone">
          <Input placeholder="Номер телефона" className={s["step-input"]} />
        </Form.Item>
        <Form.Item>
          <Button style={{ marginRight: "1em", borderRadius: "47px" }} onClick={() => stepBack()}>
            Назад
          </Button>

          <Button type="primary" htmlType="submit" style={{ borderRadius: "47px" }}>
            Далее
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
