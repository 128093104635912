/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InstagramIconBased from "../../../../icons/InstagramIconBase";
import { AppRoutesName } from "../../../../router/AppRoutesName";
import s from "./Footer.module.scss";

export const Footer = () => {
  const navigation = useNavigate();
  const { t, i18n } = useTranslation();

  const scrollToTop = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // Предотвращает стандартное поведение ссылки
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={s.wrapper}>
      <div className={s.main}>
        <div className={s.item}>
          <div>
            <button className={s.logo} onClick={() => navigation(AppRoutesName.landingPage)} />
            <div className={s.contacts}>
              <span>телефон</span>
              <a href="tel:+77057788985">+ 7 (705) 778 89 85</a>
            </div>
            <div className={s.contacts}>
              <span>эл.почта</span>
              <a href="mailto:senimsatu@gmail.com">senimsatu@gmail.com</a>
            </div>
            <div className={s.joinOurTeam}>
              <h4>{t("Landing.header.career")}</h4>
              <p>
                {t("Landing.footer.career")}
                <br />
                {t("Landing.footer.careerfirst")} <br /> {t("Landing.footer.careersecond")}
              </p>
            </div>
          </div>
          <p className={s.copyRight}>© Satoo, 2024</p>
        </div>
        <div className={s.item}>
          <div>
            {i18n.language === "ru" && (
              <h4 className={s.title}>
                Получите доступ к уникальным материалам <br /> в нашем Telegram-боте
              </h4>
            )}
            {i18n.language === "kz" && (
              <h4 className={s.title}>
                Telegram ботында <br /> бірегей материалдарға қол жеткізіңіз
              </h4>
            )}
            <ul className={s.list}>
              <li>{t("Landing.footer.telegramsectionfirst")}</li>
              <li>{t("Landing.footer.telegramsectionsecond")}</li>
              <li>{t("Landing.footer.telegramsectionthird")}</li>
              <li>{t("Landing.footer.telegramsectionfourth")}</li>
            </ul>
            <div className={s.owner}>
              <img src="images/landingPage/SultanBekbosin.png" alt="SultanBekbosin" />
              <div className={s.info}>
                <p className={s.name}>Султан Бекбосын</p>
                <p className={s.position}>Co-founder</p>
              </div>
            </div>
            <div className={s.joinOurTeamMobile}>
              <h4>{t("Landing.header.career")}</h4>
              <p>
                {t("Landing.footer.career")} <br />
                {t("Landing.footer.careerfirst")} <br /> {t("Landing.footer.careersecond")}
              </p>
            </div>
            <div className={s.socials}>
              {/* <a className={s.socialBased} href="#/">
                <TelegramIconBased color="white" background="#282828" />
              </a>
              <a className={s.socialBased} href="#/">
                <WhatsappIconBased color="white" background="#282828" />
              </a> */}
              <a
                className={s.socialBased}
                target="_blank"
                href="https://www.instagram.com/satoo.sales?igsh=czlya21iOHZ0OHk3"
                rel="noreferrer"
              >
                <InstagramIconBased color="white" background="#282828" />
              </a>
              <a href="#/" className={s.goTop} onClick={scrollToTop}>
                {t("Landing.footer.up")}
              </a>
            </div>
            <p className={s.copyRightMobile}>© Satoo, 2024</p>
          </div>
          <a className={s.publicOffer} href="#/">
            {t("Landing.footer.polytic")}
          </a>
        </div>
        <div className={s.wrap}>
          <div className={s.navigatewrap}>
            <h4 className={s.subtitle}>Навигация</h4>
            <div className={s.menu}>
              <a href="/">{t("Landing.header.main")}</a>
              <a href="#aboutUs">{t("Landing.header.aboutus")}</a>
              <a href="#cases">{t("Landing.header.cases")}</a>
              <a href="#/">{t("Landing.header.ourproducts")}</a>
              {/* <a href="#/">Статьи</a> */}
            </div>
          </div>
          <div className={s.addresswrap}>
            <h4 className={s.subtitle}>Адрес</h4>
            <a className={s.address} href="#/">
              г. Алматы, <br />
              ул. Жандосова, 198
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
