/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Table } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService, CompanyRetrieve, EmployeeList } from "../../../../api";
import s from "../../css/Company/EmployeesList.module.scss";

export const EmployeesList = () => {
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyRetrieve>();
  const [employees, setEmployees] = useState<EmployeeList[]>([]);
  const loadCompany = async (id: any) => {
    const company = await ApiService.apiAccountsCompanyRetrieve(id);
    setCompany(company);

    const result = await ApiService.apiAccountsCompanyGetCompanyEmployeesList(id);
    if (result) {
      setEmployees(
        result.map((res, index) => ({
          ...res,
          index: index + 1,
        })),
      );
    }
  };
  useEffect(() => {
    (async () => {
      loadCompany(id);
    })();
  }, []);

  return (
    <>
      <Breadcrumb
        className={s.breadcrumb}
        items={[
          {
            title: <a href="/">Все компании</a>,
          },
          {
            title: <a href={`/company/${id}`}>{company?.name}</a>,
          },
          {
            title: <a href="#">Сотрудники</a>,
          },
        ]}
      />

      <Table bordered dataSource={employees} style={{ marginTop: "2.5em" }}>
        <Table.Column title="№" dataIndex="index" />
        <Table.Column title="ФИО" dataIndex="full_name" />
        <Table.Column title="Подразделение" dataIndex="department_name" />
        <Table.Column title="Роль" dataIndex="role" />
        <Table.Column title="e-mail" dataIndex="email" />
      </Table>
    </>
  );
};
