import ProgressBar from "../../../../../../components/ProgressBar/ProgressBar";
import s from "./Employee.module.scss";

const Employee = () => {
  const segments = [
    { percent: 30, color: "#88C68E" },
    { percent: 20, color: "#E07F7F" },
    { percent: 10, color: "#AD94CD" },
    { percent: 10, color: "#03AED2" },
  ];
  return (
    <div className={s.employee}>
      <div className={s.info}>
        <span className={s.name}>Султан (23)</span>
        <div className={s.statuses}>
          <div className={s.status}>
            <span className={s.dot} style={{ backgroundColor: "#88C68E" }} />
            1%
          </div>
          <div className={s.status}>
            <span className={s.dot} style={{ backgroundColor: "#E07F7F" }} />
            20%
          </div>
          <div className={s.status}>
            <span className={s.dot} style={{ backgroundColor: "#AD94CD" }} />
            15%
          </div>
          <div className={s.status}>
            <span className={s.dot} style={{ backgroundColor: "#03AED2" }} />
            0%
          </div>
        </div>
      </div>
      <ProgressBar segments={segments} />
    </div>
  );
};
export default Employee;
