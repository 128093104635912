import { Button, DatePicker, Form, InputNumber, Space, message } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { ApiService } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

export const CreateCompanySecondStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  stepBack,
}: CreateCompanyProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      date_end: company?.package_permission?.date_end
        ? dayjs(company?.package_permission?.date_end)
        : null,
      date_start: company?.package_permission?.date_start
        ? dayjs(company?.package_permission?.date_start)
        : null,
      count_employees: company?.package_permission?.count_employees,
      minutes_for_listen_per_manager: company?.package_permission?.minutes_for_listen_per_manager,
    });
  }, [company]);

  const handleSubmit = async (values: any) => {
    console.log(values);
    try {
      await ApiService.apiAccountsCompanyUpdatePackagePermissionCreate({
        date_end: dayjs(values.date_end.$d).format("YYYY-MM-DD"),
        date_start: dayjs(values.date_start.$d).format("YYYY-MM-DD"),
        company_id: company_id!,
        count_employees: values.count_employees,
        minutes_for_listen_per_manager: values.minutes_for_listen_per_manager,
      });
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 2. Заполните пакет разрешений</h4>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          date_start: null,
          date_end: null,
        }}
      >
        <Form.Item
          label="1. Укажите время на одного менеджера (по минутам)"
          name="minutes_for_listen_per_manager"
        >
          <InputNumber className={s["step-input"]} />
        </Form.Item>
        <Space>
          <Form.Item label="2. Укажите дату начала и конца подписки">
            <div className={s.flex}>
              <span>Начало: </span>
              <Form.Item
                name="date_start"
                label="Начало"
                noStyle
                // getValueFromEvent={(e) => e && e.$d}
              >
                <DatePicker format="YYYY-MM-DD" className={`${s.gap} ${s["step-input"]}`} />
              </Form.Item>
              <span>Конец: </span>
              <Form.Item
                name="date_end"
                label="Конец"
                noStyle
                // getValueFromEvent={(e) => e && e.$d}
              >
                <DatePicker format="YYYY-MM-DD" className={`${s["step-input"]}`} />
              </Form.Item>
            </div>
          </Form.Item>
        </Space>
        <Form.Item
          label="3. Укажите количество менеджеров(линейных сотрудников)"
          name="count_employees"
        >
          <InputNumber className={s["step-input"]} />
        </Form.Item>
        <Form.Item>
          <Button style={{ marginRight: "1em", borderRadius: "47px" }} onClick={() => stepBack()}>
            Назад
          </Button>

          <Button type="primary" htmlType="submit" style={{ borderRadius: "47px" }}>
            Далее
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
