import React from "react";

interface IconProps {
  color?: string;
  style?: React.CSSProperties;
}

const LogoIcon: React.FC<IconProps> = ({ color = "#282828", style }) => {
  return (
    <svg
      style={style}
      width="91"
      height="19"
      viewBox="0 0 91 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.193481 12.56H4.51348C4.51348 12.56 4.51348 15.44 8.03348 15.44C10.4335 15.44 11.0735 14.32 11.0735 13.36C11.0735 9.84 0.513481 12.56 0.513481 6.16001C0.513481 3.28001 3.23348 0.880005 7.71348 0.880005C14.1135 0.880005 15.0735 4.56001 15.0735 6.16001H10.7535C10.7535 6.16001 10.7535 3.92 7.71348 3.92C5.95348 3.92 4.99348 4.816 4.99348 5.84C4.99348 8.72001 15.5535 6.64 15.5535 12.88C15.5535 16.08 12.8335 18.48 8.03348 18.48C1.47348 18.48 0.193481 14.48 0.193481 12.56Z"
        fill={color}
      />
      <path
        d="M21.6185 6.48001H17.1385C17.3945 2.224 20.8185 0.880005 24.6585 0.880005C28.6585 0.880005 31.8585 2.32001 31.8585 6.64001V18H27.3785V14.8H27.2185C27.2185 14.8 26.1945 18.48 21.9385 18.48C18.8985 18.48 16.6585 17.072 16.6585 14C16.6585 10.896 19.2185 9.48801 21.9705 8.78401C24.7545 8.08001 27.3785 7.85601 27.3785 6C27.3785 4.68801 26.4185 3.92 24.6585 3.92C22.7385 3.92 21.6825 4.68801 21.6185 6.48001ZM27.3785 9.616C26.6425 10.288 25.1385 10.64 23.8585 10.96C22.4185 11.312 21.2025 12.048 21.2025 13.52C21.2025 14.8 22.0985 15.6 23.5385 15.6C26.5785 15.6 27.3785 12.08 27.3785 9.616Z"
        fill={color}
      />
      <path
        d="M32.6585 1.36H47.6985V4.88H42.2585V12.72C42.2585 14.16 42.7385 14.64 44.1785 14.64C45.1385 14.64 45.6185 14.48 45.6185 14.48V17.84C45.6185 17.84 44.8185 18.16 42.8985 18.16C39.8585 18.16 37.7785 17.36 37.7785 13.52V4.88H32.6585V1.36Z"
        fill={color}
      />
      <path
        d="M70.736 9.68001C70.736 12.688 71.888 15.12 74.736 15.12C77.584 15.12 78.736 12.688 78.736 9.68001C78.736 6.67201 77.584 4.24001 74.736 4.24001C71.888 4.24001 70.736 6.67201 70.736 9.68001ZM66.256 9.68001C66.256 4.816 69.264 0.880005 74.736 0.880005C80.208 0.880005 83.216 4.816 83.216 9.68001C83.216 14.544 80.208 18.48 74.736 18.48C69.264 18.48 66.256 14.544 66.256 9.68001Z"
        fill={color}
      />
      <path
        d="M48.2374 9.67807C48.2374 4.81407 51.2454 0.878067 56.7174 0.878067C62.1894 0.878067 65.1974 4.81407 65.1974 9.67807C65.1974 14.5421 62.1894 18.4781 56.7174 18.4781C51.2454 18.4781 48.2374 14.5421 48.2374 9.67807Z"
        fill="#03AED2"
      />
      <path
        d="M55.4704 18.4028V17.1984C55.3024 15.7489 54.8509 14.5746 54.1473 13.8022C53.5907 13.1886 52.5091 12.6384 51.4065 12.4056C50.955 12.2998 50.577 12.1835 50.577 12.1411C50.577 12.0988 50.766 11.8237 50.9865 11.5381C51.9526 10.3108 53.3702 10.1415 55.2184 11.0408C55.4914 11.1784 55.7224 11.263 55.7224 11.2313C55.7224 11.1995 55.5964 10.8292 55.4494 10.4166C55.2499 9.87702 55.1659 9.44324 55.1554 8.87191C55.1449 8.17363 55.1869 7.98319 55.4809 7.39071C55.6699 7.00983 56.027 6.50199 56.279 6.24806L56.741 5.79312L57.1926 6.30097C57.4446 6.57605 57.7806 7.10505 57.9591 7.47535C58.2427 8.09957 58.2637 8.20537 58.2217 9.06235C58.1902 9.71832 58.0957 10.1627 57.9066 10.6282L57.6336 11.2736L58.4632 10.9139C59.8493 10.3108 61.0254 10.332 61.834 10.9562C62.191 11.2418 62.9261 12.1306 62.8526 12.194C62.8316 12.2046 62.3906 12.321 61.876 12.448C61.372 12.5749 60.7314 12.776 60.4584 12.9135C59.8598 13.2097 59.0617 13.9398 58.7257 14.5217C58.3792 15.1247 58.0432 16.2674 57.9696 17.1349V18.4028C56.8806 18.5514 55.7704 18.4478 55.4704 18.4028Z"
        fill="white"
      />
      <ellipse cx="87.6543" cy="15.9038" rx="2.56589" ry="2.57619" fill={color} />
    </svg>
  );
};

export default LogoIcon;
