/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Select, Table, TableColumnsType } from "antd";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import s from "./ComplianceScript.module.scss";

interface DataType {
  key: React.Key;
  block: string;
  module: ReactNode;
  percent: number;
  action: ReactNode;
}

const ComplianceScript = () => {
  const navigate = useNavigate();

  const handleSelect = (value: string) => {
    if (value === "sales") {
      navigate("/reports/sales/3434");
    }
    if (value === "postgres") {
      navigate("/reports/postgres/3434");
    }
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "Блоки",
      dataIndex: "block",
      key: "block",
    },
    {
      title: "Модули",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "%",
      dataIndex: "percent",
      key: "percent",
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <div className={s.root}>
      <div className={s.root}>
        <div className={s.header}>
          <div className={s.filter}>
            <Select className={s.select} placeholder="Выберите период">
              <Select.Option value="monthly">Месячный</Select.Option>
            </Select>
            <Select
              className={s.select}
              placeholder="Выберите"
              defaultValue="script"
              onSelect={handleSelect}
            >
              <Select.Option value="sales">Соблюдение техник продаж</Select.Option>
              <Select.Option value="script">Соблюдение скрипта</Select.Option>
              <Select.Option value="postgres">Прогресс менеджера</Select.Option>
            </Select>
          </div>
          <Button>Экспорт </Button>
        </div>
        <Breadcrumb
          className={s.breadcrumb}
          items={[
            {
              title: <a href="/reports">Общий</a>,
            },
            {
              title: <a href="#">Жакупова Айдана</a>,
            },
          ]}
        />
        <div className={s.table}>
          <Table columns={columns} bordered dataSource={[]} />
        </div>
      </div>
    </div>
  );
};
export { ComplianceScript };
