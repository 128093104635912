/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CheckCircleOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { UserTraining } from "../../../../../api";
import s from "./VideoListSection.module.scss";

interface VideoListSectionProps {
  item: UserTraining;
  onSelect: (id: string) => void;
}

const VideoListSection = ({ item, onSelect }: VideoListSectionProps) => {
  return (
    <div className={s.wrapper}>
      <div
        className={clsx(
          s.item,
          { [s.itemDanger]: !item.is_viewed },
          { [s.itemSuccess]: item.is_viewed },
        )}
        onClick={() => onSelect(item.id)}
      >
        <div
          style={{
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "1px 2px 2.1px 0px #00000040 inset",
          }}
        >
          {item.is_viewed && <CheckCircleOutlined style={{ color: "green", fontSize: "16px" }} />}
        </div>
        <span>Тема: {item.training_read.title}</span>
      </div>
    </div>
  );
};

export default VideoListSection;
