import clsx from "clsx";
import React from "react";
import s from "./Small.module.scss";

type Props = {
  color?: "blue" | "white";
  size?: "sm" | "md";
};

const SmallSpinner: React.FC<Props> = ({ color, size }) => {
  return (
    <span
      className={clsx(s.small, {
        [s.white]: color === "white",
        [s.sm]: size === "sm",
      })}
    />
  );
};

export { SmallSpinner };
