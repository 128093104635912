import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, DatePicker, Form, Input, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { openNotificationWithIcon } from "../../constants";

// const { Option } = Select;

const EditUserProfile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, setLoading] = useState(false);
  // const [profile, setProfile] = useState<User>();

  // Function to handle form submission
  const onFinish = async (values: any) => {
    // const currentUserId = getCurrentUserId();
    console.log("Received values:", values);
    // await ApiService.apiAccountsUsersPartialUpdate(currentUserId, {
    //   ...values,
    //   dob: dayjs(values.dob).format("YYYY-MM-DD"),
    //   nda: values.nda[values.nda.length - 1].originFileObj,
    // });
    openNotificationWithIcon("success", "User Profile was saved!");
    // You can send the form data to your backend here
  };

  // Function to handle form submission errors
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const loadProfile = async () => {
    setLoading(true);
    // const currentUserId = getCurrentUserId();
    // let data = await ApiService.apiAccountsUsersRetrieve(currentUserId);
    // console.log(data);
    // form.setFieldsValue({
    //   ...data,
    //   company: data.company.name,
    //   dob: data.dob && dayjs(data.dob),
    //   nda: [{ name: "NDA", url: data.nda }],
    // });
    // setProfile(data);
    setLoading(false);
  };

  useEffect(() => {
    loadProfile().finally();
  }, [form]);

  return (
    <div>
      <Row align="middle" justify="space-between">
        <Col>
          <h2> {t("My Profile")} </h2>
        </Col>
      </Row>

      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        form={form}
        name="edit_user_profile"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Avatar
            shape="square"
            size={64}
            style={{ backgroundColor: "#87d068", fontSize: 32 }}
            icon={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item name="iin" label="IIN">
          <Input placeholder="IIN" />
        </Form.Item>

        <Form.Item name="first_name" label="First name">
          <Input placeholder="first name" />
        </Form.Item>
        <Form.Item name="last_name" label="Last name">
          <Input placeholder="last name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input prefix={<UserOutlined />} placeholder="Phone" />
        </Form.Item>

        <Form.Item name="dob" label="Date of Birth">
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        {/* <Form.Item
          name="company"
          label="Company"
        >
          <Input readOnly placeholder="Company" />
        </Form.Item>
        <Form.Item
          name="contract"
          label="Contract"
        >
          <Input readOnly placeholder="Contract" />
        </Form.Item>
        <Form.Item
          name="workshift"
          label="Work Shift"
        >
          <Select>
            <Option value="5/2">5/2</Option>
            <Option value="15/15">15/15</Option>
            <Option value="30/30">30/30</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
        >
          <Select>
            <Option value="Expat">Expat</Option>
            <Option value="Local">Local</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="work_type"
          label="Work Type"
        >
          <Select>
            <Option value="Remote">Remote</Option>
            <Option value="Onsite">Onsite</Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          name="nda"
          label="NDA"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[{ required: false, message: "Please upload NDA!" }]}
        >
          <Upload
            name="nda"
            listType="text"
            beforeUpload={() => {
              return false;
            }}
          >
            <Button>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUserProfile;
