/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Form, GetRef, Input, InputRef, Popconfirm, Row, Table, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService, RopRetrieve } from "../../../../api";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";

type FormInstance<T> = GetRef<typeof Form<T>>;

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  full_name: string;
  email: string;
  department: string;
}

interface EditableRowProps {
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        aria-label="Edit item"
        onClick={toggleEdit}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleEdit();
          }
        }}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  full_name: string;
  email: string;
  department: string;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const CreateCompanyFifthStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  stepBack,
}: CreateCompanyProps) => {
  const [dataSource, setDataSource] = useState<DataType[]>([
    {
      key: "0",
      full_name: "РОП 1",
      email: "email@email.kz",
      department: "Подразделение 1",
    },
  ]);

  const [count, setCount] = useState(2);

  useEffect(() => {
    const data = company?.rops.map((x: RopRetrieve) => ({
      key: x.id,
      full_name: x.full_name,
      email: x.email,
      department: x.department_name,
    })) ?? [
      {
        key: "0",
        full_name: "РОП 1",
        email: "email@email.kz",
        department: "Подразделение 1",
      },
    ];

    setDataSource(data);
  }, [company]);

  const handleSubmit = async () => {
    try {
      console.log(dataSource);

      const rops = dataSource.map((x: DataType) => ({
        full_name: x.full_name,
        department: x.department,
        email: x.email,
      }));

      const result = await ApiService.apiAccountsCompanyFinishRopCreate({
        rops,
        company_id: company_id!,
      });

      setFetch(fetch + 1);

      console.log(result);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const handleDelete = async (key: React.Key) => {
    try {
      await ApiService.apiAccountsUserDestroy(key.toString());
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    } catch (err) {
      console.log(err);
    }
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "ФИО",
      dataIndex: "full_name",
      width: "30%",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
    },
    {
      title: "Подразделение",
      dataIndex: "department",
      editable: true,
    },
    {
      dataIndex: "operation",
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              Удалить
            </a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      full_name: `РОП ${count}`,
      email: "email@email.kz",
      department: `Подразделение ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <h4 className={s.stepTitle}>Шаг 5. Введите данные РОПа компании и подразделения</h4>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Добавить РОПа
      </Button>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        scroll={{ x: true }}
      />
      <Row style={{ marginTop: "20px" }}>
        <Button
          htmlType="submit"
          style={{ marginRight: "1em", borderRadius: "47px" }}
          onClick={() => stepBack()}
        >
          Назад
        </Button>

        <Button type="primary" style={{ borderRadius: "47px" }} onClick={() => handleSubmit()}>
          Далее
        </Button>
      </Row>
    </>
  );
};
