import { Button, Form, Input, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { ApiService, TypeEnum } from "../../../../../api";
import { CompanyDetailsModalProps } from "../../../interfaces/Company";

export const ChangeCRMModal = ({
  company_id,
  setVisible,
  visible,
  fetch,
  setFetch,
  company,
}: CompanyDetailsModalProps) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    form.setFieldsValue({
      crm_type: company?.crm_type,
      webhook_or_token: company?.webhook_or_token,
    });
  }, [company]);

  const handleSubmit = async (values: any) => {
    try {
      await ApiService.apiAccountsCompanyPartialUpdate(company_id!, {
        crm_type: values.crm_type,
        webhook_or_token: values.webhook_or_token,
      });
      message.success("Данные CRM успешно сохранены");
      setVisible(false);
      setFetch(fetch + 1);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const handleTestConnection = async () => {
    try {
      await ApiService.apiAccountsCompanyTestCrmConnectionCreate({
        type: form.getFieldValue("crm_type"),
        webhook_or_token: form.getFieldValue("webhook_or_token"),
      });
      message.success("Connection tested successfully");
      setDisabled(false);
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <Modal
      title="Изменить CRM"
      open={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => handleTestConnection()}>
          Проверить доступ
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()} disabled={disabled}>
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} style={{ marginTop: "1em" }} onFinish={handleSubmit}>
        <Form.Item name="crm_type" label="Тип CRM">
          <Select onChange={() => setDisabled(true)}>
            <Select.Option value={TypeEnum.BITRIX}>Bitrix</Select.Option>
            <Select.Option value={TypeEnum.AMO_CRM}>Amo CRM</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="webhook_or_token" label="Вебхук/Токен">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
