import React, { ComponentProps } from "react";
import clsx from "clsx";
import s from "./styles.module.scss";
import { SmallSpinner } from "../../Spinner/Small";
import { BaseClickableProps } from "./Button.type";

export type TButton = ComponentProps<"button"> &
  BaseClickableProps & {
    children: React.ReactNode;
  };

const Button: React.FC<TButton> = ({
  children,
  className,
  fullWidth,
  color,
  loading,
  ...otherProps
}) => {
  return (
    <button
      className={clsx([
        {
          [s.base]: true,
          [s[otherProps.variant ?? "default"]]: true,
          [s.disabled]: otherProps.disabled || loading,
          [s.pressed]: otherProps.pressed,
          [s.fullWidth]: fullWidth,
          [s.blue]: color === "blue",
          [s.secondary]: color === "secondary",
          [s.link]: color === "link",
        },
        className,
      ])}
      {...otherProps}
      disabled={otherProps.disabled || loading}
    >
      {children}
      {loading && <SmallSpinner color="white" size="sm" />}
    </button>
  );
};

export default Button;
